import {defineStore} from 'pinia'
import {snackbarStore} from "@/store/snackbarStore";
import {userStore} from "@/store/userStore";
import axios from "axios";

export const fixtureStore = defineStore('fixtureStore', {
    state: () => {
        return {
            fixtureInfos: null,
            showFixtureInfos: false,
            allInfoTeam: null
        }
    },
    getters: {
        getFixtureInfos: (state) => {
            return state.fixtureInfos;
        },
        getShowDialogFixtureInfos: (state) => {
            return state.showFixtureInfos;
        },
        getAllInfoTeam: (state) => {
            return state.allInfoTeam;
        },
    },
    actions: {
        async callFixtureInfos(id) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_FIXTURE + "/" + id, {
                    headers: {
                        Authorization: 'Bearer ' + userStore().getToken
                    }
                });
                this.fixtureInfos = response.data;
                return true;
            } catch (error) {
                snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                return false;
            }
        },
        async callAllInfoTeam() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_TEAM, {
                    headers: {
                        Authorization: 'Bearer ' + userStore().getToken
                    }
                });
                console.log(response.data);
                this.allInfoTeam = response.data;
                return true;
            } catch (error) {
                snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                return false;
            }
        }
    }
})