<template>
  <section id="edit-notification" class="bg-white">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/menu" textBreadcrumbs="Modifier Notifications"/>
    <v-container style="padding: 80px 10px;">
      <v-switch
          v-model="people"
          color="primary"
          label="Recevoir les emails de rappels sur les paris non parié"
          value="John"
          hide-details
      ></v-switch>
      <v-switch
          v-model="people"
          color="primary"
          label="Recevoir les emails de classement à la fin de semaine"
          value="John"
          hide-details
      ></v-switch>
      <v-switch
          :model-value="false"
          label="off loading"
          loading="warning"
          disabled
      ></v-switch>
    </v-container>
    <BottomNavigationComponent />
  </section>
</template>

<script>
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent";
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import {useVuelidate} from "@vuelidate/core/dist/index.mjs";

export default {
  name: 'EditNotificationView',
  components: {BreadcrumbsComponent, BottomNavigationComponent},
  data() {
    return {
      avatarImg: null,
      backgroundImg: null,
      loadingEditInfoBtn: false
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
    }
  },
}
</script>
<style scoped>
#edit-notification {
  height: 100vh;
}
</style>
