<template>
  <section id="forgot-password" class="bg-blue-darken-4">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/login" textBreadcrumbs="Mot de passe oublié"/>
    <div style="padding-top: 80px">
    <v-img
        class="ma-auto pt-16"
        :width="300"
        aspect-ratio="1/1"
        :cover="true"
        :src="require('@/assets/images/background-connection-page.png')"
    ></v-img>
    <h4 class="text-center">Cela arrive même aux meilleurs !</h4>
    <v-sheet class="bg-blue-darken-4 mx-auto d-flex align-center justify-center flex-column">
      <v-form class="w-75 mt-10">
        <div class="bg-white px-5 pt-8 pb-3 mb-5 rounded">
          <v-text-field
              variant="solo"
              v-model="email" :error-messages="v$.email.$errors.map(e => e.$message)" @input="v$.email.$touch" @blur="v$.email.$touch"
              type="email"
          >
            <template v-slot:label>
              Email<span class="text-error ml-1"> *</span>
            </template>
          </v-text-field>
        </div>
        <p class="mb-8 text-center">Entrez votre adresse email, nous vous enverrons un email avec un lien vous permettant de réinitialiser votre mot de passe !</p>
        <v-btn :loading="loadingForgotPasswordBtn" size="x-large" color="white" class="font-weight-bold ma-auto d-flex justify-center align-center align-content-center" @click="sendForgotPassword()">Envoyer</v-btn>
      </v-form>
    </v-sheet>
    </div>
  </section>
</template>

<script>
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import { useVuelidate } from '@vuelidate/core'
import {email, helpers, required} from '@vuelidate/validators'
import {userStore} from "@/store/userStore";

export default {
  name: 'ForgotPasswordView',
  components: {BreadcrumbsComponent},
  data() {
    return {
      email: null,
      loadingForgotPasswordBtn: false
    }
  },
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      email: {
        required: helpers.withMessage("L'e-mail est requis.", required),
        email: helpers.withMessage("L'e-mail n'est pas une adresse e-mail valide.", email)
      },
    }
  },
  methods: {
    async sendForgotPassword() {
      this.loadingForgotPasswordBtn = true;
      const result = await this.v$.$validate()
      if (!result) {
        this.loadingForgotPasswordBtn = false;
        return false;
      }
      await userStore().forgotPassword(this.email);
      this.loadingForgotPasswordBtn = false;
    }
  }
}
</script>
<style scoped>
#forgot-password {
  height: 100vh;
}
:deep(.v-label) {
  opacity: 1 !important;
}
:deep(.v-messages) {
  text-align: left !important;
}
</style>
