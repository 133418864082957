<template>
  <section id="userInfosComponent">
    <v-dialog
        style="z-index: 9999"
        v-model="getShowDialogUserInfos"
        fullscreen
        transition="dialog-bottom-transition"
    >
      <v-card class="pb-15">
        <v-card-text class="pa-0">
          <section id="profil2" :style="{
    backgroundImage: getUserInfos.backgroundUrl ? 'url(' + (linkApi + 'users/background/' + getUserInfos.backgroundUrl) + ')' : 'none',
    backgroundColor: getUserInfos.backgroundUrl ? 'transparent' : '#0D47A1'
}" class="d-flex justify-center align-center flex-column">
            <v-row style="position: absolute;
    top: 30px;
    right: 30px;">
              <v-icon @click="closeDialog" style="background: rgba(0,0,0,0.5); border-radius: 50%; padding: 15px;" class="text-white" icon="mdi-close"></v-icon>
            </v-row>
            <v-avatar v-if="getUserInfos.avatarUrl != null" size="80" style="font-size: 35px" :image="linkApi + 'users/avatar/' + getUserInfos.avatarUrl">
            </v-avatar>
            <v-avatar v-else size="80" style="font-size: 35px" class="bg-white font-weight-bold">
              {{ getUserInfos.name.slice(0, 2).toUpperCase() }}
            </v-avatar>
            <p class="ma-0 mt-2 pa-2 px-8 rounded text-white text-h5 titleSection">{{ getUserInfos.nickname }}</p>
            <p class="ma-0 rounded-b pa-1 text-white text-body-2 font-weight-bold titleSection">{{ getUserInfos.name }}</p>
          </section>
          <section class="py-5 bg-white">
            <v-row no-gutters class="text-center d-flex justify-center align-center">
              <v-col col="6">
                <p class="ma-0 text-h5 font-weight-black">{{ getUserInfos.score.point }}</p>
                <p class="ma-0 bg-white">Points</p>
              </v-col>
              <v-col col="6">
                <p v-if="getUserInfos.score.position === 1" class="ma-0 text-h5 font-weight-black">{{ getUserInfos.score.position }}er</p>
                <p v-else class="ma-0 text-h5 font-weight-black">{{ getUserInfos.score.position }}ème</p>
                <p class="ma-0">Classement</p>
              </v-col>
            </v-row>
          </section>
          <section class="py-5 bg-white">
            <v-row no-gutters class="text-center d-flex justify-center align-center">
              <v-col col="4">
                <p class="ma-0 text-h5 font-weight-black">{{ getUserInfos.score.winBet }}<span class="font-weight-regular text-subtitle-2">/51</span></p>
                <p class="ma-0">Pronos reussi</p>
              </v-col>
              <v-col col="4">
                <p class="ma-0 text-h5 font-weight-black">{{ getUserInfos.score.perfectWinBet }}<span class="font-weight-regular text-subtitle-2">/51</span></p>
                <p class="ma-0">Pronos parfaits</p>
              </v-col>
              <v-col col="4">
                <p class="ma-0 text-h5 font-weight-black">{{ getUserInfos.score.lostBet }}<span class="font-weight-regular text-subtitle-2">/51</span></p>
                <p class="ma-0">Pronos perdus</p>
              </v-col>
            </v-row>
          </section>
          <section class="py-5 bg-white">
            <v-container>
              <v-card elevation="16" class="d-flex align-center justify-center flex-column">
                <v-card-title class="text-center">Ses Pronos</v-card-title>
                <v-card-text class="w-100 pa-0">
                  <v-row :no-gutters="true">
                    <v-col cols="5" class="text-center d-flex justify-center align-center align-content-center flex-row">
                      <p>Résultat</p>
                    </v-col>
                    <v-col cols="4" class="text-center d-flex justify-center align-center align-content-center flex-row">
                      <p>Ses Pronos</p>
                    </v-col>
                    <v-col cols="3" class="text-center d-flex justify-center align-center align-content-center flex-row">
                      <p>Ses Points</p>
                    </v-col>
                  </v-row>
                  <v-row :no-gutters="true" v-for="bet in getUserInfos.bet" v-bind:key="bet.id">
                    <v-col cols="12" class="d-flex justify-center align-content-center">
                      <v-progress-linear v-if="bet.state === 'FINISH'" :height="8" style="font-size: 10px" class="text-white w-25" model-value="100" color="light-green-darken-1">Terminé</v-progress-linear>
                      <v-progress-linear v-if="bet.state === 'SCHEDULED'" :height="8" style="font-size: 10px" class="text-white w-25" model-value="100" color="red-lighten-2">Non commencé</v-progress-linear>
                      <v-progress-linear v-if="bet.state === 'STARTED'" :height="8" style="font-size: 10px" class="text-black w-25" model-value="100" color="amber-darken-4" indeterminate>En cours</v-progress-linear>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="5" class="text-center d-flex justify-center align-center align-content-center flex-column">
                      <v-row :no-gutters="true" class="w-100 d-flex flex-nowrap justify-center align-center align-content-center flex-row">
                        <country-flag class="ma-0 pa-0 text-center" :rounded="true" :country="bet.isoTeam1" size='small' /><p>
                        {{ bet.goalTeam1 }}</p>
                        <span class="mx-1">-</span>
                        <p>{{ bet.goalTeam2 }}</p><country-flag class="ma-0 pa-0 text-center" :rounded="true" :country="bet.isoTeam2" size="small" />
                      </v-row>
                    </v-col>
                    <v-col cols="4" class="text-center d-flex justify-center align-center align-content-center flex-column">
                      <v-row :no-gutters="true" class="w-100 d-flex flex-nowrap justify-center align-center align-content-center flex-row">
                        <template v-if="bet.betUserTeam1 !== null && bet.betUserTeam2 !== null">
                          <country-flag class="ma-0 pa-0 text-center" :rounded="true" :country="bet.isoTeam1" size='small' /><p>
                          {{ bet.betUserTeam1 }}</p>
                          <span class="mx-1">-</span>
                          <p>{{ bet.betUserTeam2 }}</p><country-flag class="ma-0 pa-0 text-center" :rounded="true" :country="bet.isoTeam2" size="small" />
                        </template>
                        <p v-else>Aucun pari</p>
                      </v-row>
                    </v-col>
                    <v-col cols="3" class="text-center d-flex justify-center align-center align-content-center flex-column">
                      <v-row :no-gutters="true" v-if="bet.state === 'SCHEDULED'" class="d-flex justify-center align-center align-content-center flex-column">
                        À venir
                      </v-row>
                      <v-row :no-gutters="true" v-else class="d-flex justify-center align-center align-content-center flex-column">
                        <v-col v-if="bet.scoreBet == null" col="12" class="text-center d-flex justify-center align-center align-content-center flex-row">
                          <p class="font-weight-bold">0</p>
                        </v-col>
                        <v-col v-else col="12" class="text-center d-flex justify-center align-center align-content-center flex-row">
                          <p class="font-weight-bold">{{ bet.scoreBet }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-progress-linear color="primary" class="mb-1" :height="1"></v-progress-linear>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </section>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import {leadboardStore} from "@/store/leadboardStore";
import {userStore} from "@/store/userStore";

export default {
  name: 'UserInfosComponent',
  computed: {
    getShowDialogUserInfos() {
      return leadboardStore().getShowDialogUserInfos;
    },
    getUserInfos() {
      return userStore().getOtherUserInfos;
    }
  },
  methods: {
    closeDialog() {
      leadboardStore().showDialogUserInfos = false;
    }
  },
  data() {
    return {
      linkApi: process.env.VUE_APP_API_URL_PUBLIC,
      valueOpenDialogUserInfos: this.openDialogUserInfos
    }
  },
}
</script>
<style scoped>
#profil2 {
  height: 200px;
  background-size: cover;
  background-position: center;
}
.v-btn .v-btn__content .v-icon {
  color: black;
}
.titleSection {
  background: rgba(0, 0, 0, 0.81);
}
</style>
