<template>
  <span v-if="seconds == null && minutes == null && hours == null && days == null"><v-progress-circular color="blue" indeterminate :size="15"></v-progress-circular><br></span>
  <span v-if="(seconds > 0) || (hours > 0) || (minutes > 0) || (days > 0)">Clôture dans </span>
  <span v-if="days !== 0">{{ days }}</span>
  <span v-if="days > 1"> jours </span>
  <span v-if="days === 1"> jour </span>
  <span v-if="hours !== 0">{{ hours }}</span>
  <span v-if="hours > 1"> heures </span>
  <span v-if="hours === 1"> heure </span>
  <span v-if="minutes !== 0">{{ minutes }}</span>
  <span v-if="minutes > 1"> minutes </span>
  <span v-if="minutes === 1"> minute </span>
  <span v-if="seconds !== 0">{{ seconds }}</span>
  <span v-if="seconds > 1"> secondes</span>
  <span v-if="seconds === 1"> seconde</span>
</template>


<script>
export default {
  emits: ['countdownZero'],
  name: "TimerComponent",
  props: {
    dateStart: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      seconds: null,
      minutes: null,
      hours: null,
      days: null,
      years: null,
      lunchData: null
    }
  },
  created() {
    setInterval(() => {
      const currentDate = new Date();
      const lunchTime = new Date(this.dateStart);
      if (currentDate.getTime() < lunchTime.getTime()) {
        const timeDifference = lunchTime.getTime() - currentDate.getTime();
        const totalSeconds = Math.floor(timeDifference / 1000);
        this.days = Math.floor(totalSeconds / 86400); // Calcul du nombre de jours
        this.hours = Math.floor((totalSeconds % 86400) / 3600);
        this.minutes = Math.floor((totalSeconds % 3600) / 60);
        this.seconds = Math.floor(totalSeconds % 60);
        if(this.seconds <= 0 && this.minutes <= 0 && this.hours <= 0 && this.days <= 0) {
          setTimeout(() => {
            this.$emit("countdownZero");
          }, 5000);
        }
      }
    }, 1000);
  },

};
</script>