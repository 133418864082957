<template>
  <section id="startedMatche">
    <div v-if="getMatcheStarted === null" class="center-container">
      <v-progress-circular
          color="blue-darken-4"
          indeterminate
          :size="80"
          :width="10"
      ></v-progress-circular>
      <span class="text-h5">Chargement des données...</span>
    </div>
    <div v-else>
      <div v-if="getMatcheStarted.length === 0" class="center-container">
        <v-empty-state
            icon="mdi-emoticon-sad-outline"
            text="Il n'y a pour l'instant aucun match en cours !"
            title="Aucun match"
        ></v-empty-state>
      </div>
      <div v-for="matcheScheduled in getMatcheStarted" v-bind:key="matcheScheduled.id" class="my-3">
      <p class="text-center text-capitalize">{{ formatDate(matcheScheduled.dateStart) }}</p>
      <v-card elevation="3" loading>
        <v-row :no-gutters="true" class="pt-5">
          <v-col cols="3" class="justify-center align-center d-flex flex-column">
            <div style="font-size:65px">
              <div v-if="matcheScheduled.goalTeam1 > matcheScheduled.goalTeam2">
                <country-flag :rounded="true" :country="matcheScheduled.team1.isoFlag" size='big' style="box-shadow: 0px 0px 10px 5px rgba(87,239,110,0.99)"/>
              </div>
              <div v-else-if="matcheScheduled.goalTeam1 < matcheScheduled.goalTeam2">
                <country-flag :rounded="true" :country="matcheScheduled.team1.isoFlag" size='big' style="box-shadow: 0px 0px 10px 5px rgba(255,102,102,0.99)"/>
              </div>
              <div v-else>
                <country-flag :rounded="true" :country="matcheScheduled.team1.isoFlag" size='big' style="box-shadow: 0px 0px 10px 5px rgba(173,173,173,0.99)"/>
              </div>
            </div>
            <p>{{ matcheScheduled.team1.name }}</p>
          </v-col>
          <v-col cols="6">
            <v-row :no-gutters="true">
              <v-col cols="12" class="pb-1 text-center">
                {{ matcheScheduled.type }}
              </v-col>
              <v-col cols="5" class="text-center font-weight-bold text-blue-darken-4" style="font-size: 32px;">
                <p v-if="matcheScheduled.goalTeam1 !== null" style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid rgba(227,227,227,0.99); color: black;">{{ matcheScheduled.goalTeam1 }}</p>
                <p v-else style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid rgba(227,227,227,0.99); color: black;">0</p>
              </v-col>
              <v-col col="2" class="d-flex justify-center align-center">
                <p>-</p>
              </v-col>
              <v-col cols="5" class="text-center font-weight-bold" style="font-size: 32px;">
                <p v-if="matcheScheduled.goalTeam2 !== null" style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid rgba(227,227,227,0.99); color: black;">{{ matcheScheduled.goalTeam2 }}</p>
                <p v-else style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid rgba(227,227,227,0.99); color: black;">0</p>
              </v-col>
              <v-col cols="12" class="text-center" v-if="matcheScheduled.pool !== null">
                <p class="mt-3" style="font-size: 12px">Groupe {{ matcheScheduled.pool }}</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" class="justify-center align-center d-flex flex-column">
            <div style="font-size:65px;">
              <div v-if="matcheScheduled.goalTeam2 > matcheScheduled.goalTeam1">
                <country-flag :rounded="true" :country="matcheScheduled.team2.isoFlag" size='big' style="box-shadow: 0px 0px 10px 5px rgba(87,239,110,0.99)"/>
              </div>
              <div v-else-if="matcheScheduled.goalTeam2 < matcheScheduled.goalTeam1">
                <country-flag :rounded="true" :country="matcheScheduled.team2.isoFlag" size='big' style="box-shadow: 0px 0px 10px 5px rgba(255,102,102,0.99)"/>
              </div>
              <div v-else>
                <country-flag :rounded="true" :country="matcheScheduled.team2.isoFlag" size='big' style="box-shadow: 0px 0px 10px 5px rgba(173,173,173,0.99)"/>
              </div>
            </div>
            <p>{{ matcheScheduled.team2.name }}</p>
          </v-col>
        </v-row>
        <div v-if="matcheScheduled.betUser">
          <v-row :no-gutters="true" class="ma-2 pa-2 bg-grey-lighten-2 rounded">
            <v-col cols="4" class="d-flex justify-center align-center align-content-center">
              <v-row :no-gutters="true">
                <v-col cols="12" class="text-caption text-center">
                  Mon prono :
                </v-col>
                <v-col cols="5" class="text-right">
                  {{ matcheScheduled.betUser.goalUserTeam1 }}
                </v-col>
                <v-col cols="2" class="text-center">
                  -
                </v-col>
                <v-col cols="5" class="text-left">
                  {{ matcheScheduled.betUser.goalUserTeam2 }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-row :no-gutters="true">
                <v-col cols="12" class="text-caption text-center">
                  Score :
                </v-col>
                <v-col cols="12" class="text-center">
                  {{ matcheScheduled.betUser.point }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5" class="d-flex justify-center align-content-center align-center">
              <v-btn @click="showFixtureInfos(matcheScheduled.id)" class="text-white font-weight-bold" color="light-blue-darken-1" prepend-icon="mdi-information">Infos</v-btn>
              <v-btn @click="showTchat" size="small" class="mx-2" icon="mdi-chat"></v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row :no-gutters="true" class="ma-2 pa-2 bg-grey-lighten-2 rounded">
            <v-col cols="4" class="d-flex justify-center align-center align-content-center">
              <v-row :no-gutters="true">
                <v-col cols="12" class="text-caption text-center">
                  Mon prono :
                </v-col>
                <v-col cols="12" class="text-center">
                  Aucun
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-row :no-gutters="true">
                <v-col cols="12" class="text-caption text-center">
                  Score :
                </v-col>
                <v-col cols="12" class="text-center">
                  0
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5" class="d-flex justify-center align-content-center align-center">
              <v-btn @click="showFixtureInfos(matcheScheduled.id)" class="text-white font-weight-bold" color="light-blue-darken-1" prepend-icon="mdi-information">Infos</v-btn>
              <v-btn @click="showTchat" size="small" class="mx-2" icon="mdi-chat"></v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
    </div>
  </section>
  <MatcheInfosComponent />
</template>
<script>
import {matcheStore} from "@/store/matcheStore";
import {fixtureStore} from "@/store/fixtureStore";
import MatcheInfosComponent from "@/components/Matche/MatcheInfosComponent";
import router from "@/router";

export default {
  name: 'StartedMatcheComponent',
  components: {MatcheInfosComponent},
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    async active(newValue) {
      if (newValue) {
        await matcheStore().getMatche("STARTED", true);
      }
    }
  },
  methods: {
    showTchat() {
      router.push("/tchat");
    },
    formatDate(dateString) {
      const options = { weekday: 'long', day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('fr-FR', options);
    },
    async showFixtureInfos(id) {
      if (await fixtureStore().callFixtureInfos(id)) {
        fixtureStore().showFixtureInfos = true;
      }
    }
  },
  computed: {
    getMatcheStarted() {
      return matcheStore().getMatcheStarted
    }
  },
  async created() {
    await matcheStore().getMatche("STARTED", true);
  },
}
</script>
<style>
.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 82vh;
  flex-direction: column;
  overflow: hidden !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>