<template>
  <section class="comingMatches bg-white">
    <div v-if="getMatcheScheduled === null" class="center-container">
      <v-progress-circular
          color="blue-darken-4"
          indeterminate
          :size="80"
          :width="10"
      ></v-progress-circular>
      <span class="text-h5">Chargement des données...</span>
      {{getMatcheScheduled}}
    </div>
    <div v-else>
      <div v-if="getMatcheScheduled.length === 0" class="center-container">
        <v-empty-state
            icon="mdi-emoticon-sad-outline"
            text="Tous les matchs de l'Euro sont finis !"
            title="Aucun match"
        ></v-empty-state>
      </div>
      <div v-for="matcheScheduled in getMatcheScheduled" v-bind:key="matcheScheduled.id" class="my-3">
      <p class="text-center text-capitalize">{{ formatDate(matcheScheduled.dateStart) }}</p>
        <p class="text-center" style="font-size: 11px"><TimerComponent :dateStart="matcheScheduled.dateStart" @countdownZero="callMatcheStore" /></p>
      <v-card elevation="3">
        <v-row :no-gutters="true" class="pt-5">
          <v-col cols="3" class="justify-center align-center d-flex flex-column">
            <div style="font-size:65px">
              <country-flag :rounded="true" :country="matcheScheduled.team1.isoFlag" size='big' style="box-shadow: rgba(227,227,227,0.99) 0px 0px 3px, rgba(227,227,227,0.99) 0px 0px 0px 3px;"/>
            </div>
            <p>{{ matcheScheduled.team1.name }}</p>
          </v-col>
          <v-col cols="6">
            <v-row :no-gutters="true">
              <v-col cols="12" class="pb-2 text-center text-caption">
                {{ matcheScheduled.type }}
              </v-col>
              <v-col cols="5" class="text-left font-weight-bold text-blue-darken-4" style="font-size: 32px;" v-if="matcheScheduled.betUser">
                <input
                    v-model.number="matcheScheduled.betUser.goalUserTeam1"
                    pattern="[0-9]*"
                    type="number"
                    class="text-center"
                    max="99"
                    min="0"
                    style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid #143cdb; color: #143cdb;"
                />
              </v-col>
              <v-col cols="5" class="text-left font-weight-bold text-blue-darken-4" style="font-size: 32px;" v-else>
                <input
                    v-model.number="matcheScheduled.addBetTeam1"
                    pattern="[0-9]*"
                    type="number"
                    class="text-center"
                    max="99"
                    min="0"
                    style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid #143cdb; color: #143cdb;"
                />
              </v-col>
              <v-col col="2" class="d-flex justify-center align-center">
                <p>-</p>
              </v-col>
              <v-col cols="5" class="text-right font-weight-bold" style="font-size: 32px;" v-if="matcheScheduled.betUser">
                <input
                    v-model.number="matcheScheduled.betUser.goalUserTeam2"
                    pattern="[0-9]*"
                    type="number"
                    class="text-center"
                    max="99"
                    min="0"
                    style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid #143cdb; color: #143cdb;"
                />
              </v-col>
              <v-col cols="5" class="text-right font-weight-bold" style="font-size: 32px;" v-else>
                <input
                    v-model.number="matcheScheduled.addBetTeam2"
                    pattern="[0-9]*"
                    type="number"
                    class="text-center"
                    max="99"
                    min="0"
                    style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid #143cdb; color: #143cdb;"
                />
              </v-col>
              <v-col cols="12" class="text-center" v-if="matcheScheduled.pool !== null">
                <p class="mt-3" style="font-size: 12px">Groupe {{ matcheScheduled.pool }}</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" class="justify-center align-center d-flex flex-column">
            <div style="font-size:65px;">
              <country-flag :rounded="true" :country="matcheScheduled.team2.isoFlag" size='big' style="box-shadow: rgba(227,227,227,0.99) 0px 0px 3px, rgba(227,227,227,0.99) 0px 0px 0px 3px;"/>
            </div>
            <p>{{ matcheScheduled.team2.name }}</p>
          </v-col>
        </v-row>
        <v-row :no-gutters="true">
          <v-col cols="12" class="d-flex flex-row justify-center" v-if="matcheScheduled.betUser">
            <v-btn @click="putBetUser(matcheScheduled.id, matcheScheduled.betUser.goalUserTeam1, matcheScheduled.betUser.goalUserTeam2)" class="my-5 ml-0 px-16 font-weight-bold text-white" color="orange" :disabled="!(matcheScheduled.betUser.goalUserTeam1 !== '' && matcheScheduled.betUser.goalUserTeam2 !== '' && matcheScheduled.betUser.goalUserTeam1 !== undefined && matcheScheduled.betUser.goalUserTeam2 !== undefined)">Modifier</v-btn>
          </v-col>
          <v-col cols="12" class="d-flex flex-row justify-center" v-else>
            <v-btn @click="putBetUser(matcheScheduled.id, matcheScheduled.addBetTeam1, matcheScheduled.addBetTeam2)" class="my-5 ml-0 px-16 font-weight-bold" color="green" :disabled="!((matcheScheduled.addBetTeam1 !== '' && matcheScheduled.addBetTeam1 !== undefined) && (matcheScheduled.addBetTeam2 !== '' && matcheScheduled.addBetTeam2 !== undefined))">Valider</v-btn><!--TODO-->
          </v-col>
        </v-row>
      </v-card>
    </div>
    </div>
  </section>
</template>
<script>
import {matcheStore} from "@/store/matcheStore";
import TimerComponent from "@/components/General/TimerComponent";

export default {
  name: 'ScheduledMatchComponent',
  components: {TimerComponent},
  data() {
    return {
    };
  },
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    async active(newValue) {
      if (newValue) {
        await matcheStore().getMatche("SCHEDULED", true);
      }
    }
  },
  methods: {
    async putBetUser(fixtureId, goalTeam1, goalTeam2) {
      try {
        let res = await matcheStore().putBetUser(fixtureId, goalTeam1, goalTeam2);
        if (res) {
          let fixtureFind = matcheStore().getMatcheScheduled.find(fixture => fixture.id === fixtureId);
          fixtureFind.betUser = {};
          fixtureFind.betUser.goalUserTeam1 = goalTeam1;
          fixtureFind.betUser.goalUserTeam2 = goalTeam2;
        }
      } catch (error) {
        console.error(error);
      }
    },
    formatDate(dateString) {
      const options = { weekday: 'long', day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('fr-FR', options);
    },
    async callMatcheStore() {
      await matcheStore().getMatche("SCHEDULED", true);
    },
    limitInput(event, value) {
      console.log(value);
      // Autoriser seulement les chiffres, la touche Backspace (code 8) et la touche Delete (code 46)
      if ((event.key >= '0' && event.key <= '9') || event.key === 'Backspace' || event.key === 'Delete') {
        // Vérifier si la nouvelle valeur après l'événement dépasse la limite de 2 caractères
        if (value.length >= 2 && event.key !== 'Backspace' && event.key !== 'Delete') {
          event.preventDefault(); // Empêcher l'ajout de caractères supplémentaires
        }
      } else {
        event.preventDefault(); // Bloquer les autres caractères (virgule, lettre "e", etc.)
      }
    },
  },
  computed: {
    getMatcheScheduled() {
      return matcheStore().getMatcheScheduled
    }
  },
  async created() {
    await matcheStore().getMatche("SCHEDULED", true);
  },
}
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>