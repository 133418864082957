import {defineStore} from 'pinia'
import axios from "axios";
import {snackbarStore} from "@/store/snackbarStore";
import {userStore} from "@/store/userStore";

export const matcheStore = defineStore('matcheStore', {
    state: () => {
        return {
            matcheScheduled: null,
            matcheStarted: null,
            matcheFinish: null,
            nbMatcheScheduledNotBet: 0,
        }
    },
    getters: {
        getMatcheScheduled: (state) => {
            return state.matcheScheduled;
        },
        getNbMatcheScheduledNotBet: (state) => {
            return state.nbMatcheScheduledNotBet;
        },
        getMatcheStarted: (state) => {
            return state.matcheStarted;
        },
        getMatcheFinish: (state) => {
            return state.matcheFinish;
        },
    },
    actions: {
        async getMatche(state, betUser) {
            await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_BET, {
                params: {
                    state: state,
                    betUser: betUser
                },
                headers: {
                    Authorization: 'Bearer ' + userStore().getToken
                }
            }).then(async response => {
                if(state === "SCHEDULED") {
                    this.matcheScheduled = response.data;
                    this.nbMatcheScheduledNotBet = response.data.filter(match => !Object.prototype.hasOwnProperty.call(match, 'betUser')).length;
                } else if(state === "STARTED") {
                    this.matcheStarted = response.data;
                } else if(state === "FINISH") {
                    this.matcheFinish = response.data;
                }
                return true;
            }).catch(error => {
                    snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                    return false;
                }
            );
        },
        async putBetUser(fixtureId, goalTeam1, goalTeam2) {
            try {
                const response = await axios.put(
                    process.env.VUE_APP_API_URL + process.env.VUE_APP_API_BET,
                    {
                        fixtureId: fixtureId,
                        goalTeam1: goalTeam1,
                        goalTeam2: goalTeam2
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + userStore().getToken
                        }
                    }
                );
                snackbarStore().showNotification(response.data.message, true, "green-darken-1", 3000);
                return true;
            } catch (error) {
                snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                return false;
            }
        }
    }
})