<template>
  <section id="admin" class="bg-white">
    <v-container class="bg-white" style="padding-bottom: 150px">
      <v-row>
        <v-col cols="12">
          <h2>Sélectionner une fixture ID :</h2>
          <div v-for="fixtureAll in getAllFixture" v-bind:key="fixtureAll.id">
            <p>{{fixtureAll.id}} {{fixtureAll.team1.name}} - {{fixtureAll.team2.name}} [{{fixtureAll.goalTeam1}}-{{fixtureAll.goalTeam2}}] [{{fixtureAll.dateFinish}}]</p>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-center align-center align-content-center">
          <v-row :no-gutters="true">
            <p>Numéro de l'id</p>
            <v-col cols="12" class="text-left font-weight-bold text-blue-darken-4" style="font-size: 32px;">
              <input
                  v-model="idFixture"
                  pattern="[0-9]*"
                  type="number"
                  class="text-center"
                  max="99"
                  min="0"
                  style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid #143cdb; color: #143cdb;"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="d-flex justify-center align-center align-content-center">
          <v-row :no-gutters="true">
            <v-col cols="5" class="text-left font-weight-bold text-blue-darken-4" style="font-size: 32px;">
              <p>Team1 score</p>
              <input
                  v-model="team1Score"
                  pattern="[0-9]*"
                  type="number"
                  class="text-center"
                  max="99"
                  min="0"
                  style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid #143cdb; color: #143cdb;"
              />
            </v-col>
            <v-col col="2" class="d-flex justify-center align-center">
              <p>-</p>
            </v-col>
            <v-col cols="5" class="text-right font-weight-bold" style="font-size: 32px;">
              <p>Team2 score</p>
              <input
                  v-model="team2Score"
                  pattern="[0-9]*"
                  type="number"
                  class="text-center"
                  max="99"
                  min="0"
                  style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid #143cdb; color: #143cdb;"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-content-center align-center">
        <v-col>
          <v-checkbox v-model="finish" label="Le Matche est-il terminé ?"></v-checkbox>
        </v-col>
      </v-row>
      <v-btn @click="sendData" color="warning" class="w-100 font-weight-bold">Modifier</v-btn>
    </v-container>
  </section>
  <BottomNavigationComponent />
</template>

<script>
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent";
import {adminStore} from "@/store/adminStore";
export default {
  name: 'AdminView',
  components: {BottomNavigationComponent},
  data() {
    return {
      finish: false,
      team2Score: null,
      team1Score: null,
      idFixture: null,
    }
  },
  computed: {
    getAllFixture() {
      return adminStore().getDataFixture;
    }
  },
  methods: {
    async sendData() {
      await adminStore().putDataFixture(this.idFixture, this.team1Score, this.team2Score, this.finish);
      await adminStore().callGetAllFixture();
    }
  },
  async mounted() {
    await adminStore().callGetAllFixture();
  }
}
</script>
<style scoped>
#admin {
  height: 100vh;
}
</style>
