<template>
  <section id="match" class="bg-white">
    <v-tabs v-model="tabs" class="position-fixed w-100" bg-color="blue-darken-4" style="z-index: 1;" grow>
      <v-tab :value="1" class="font-weight-bold">A VENIR</v-tab>
      <v-tab :value="2" class="font-weight-bold">EN DIRECT</v-tab>
      <v-tab :value="3" class="font-weight-bold">TERMINÉS</v-tab>
    </v-tabs>
    <v-window v-model="tabs" class="pt-12 px-3 bg-white" style="padding-bottom: 100px !important;">
      <v-window-item v-for="i in 3" :key="i" :value="i">
        <div v-if="i === 1">
          <ScheduledMatcheComponent :active="tabs === i"/>
        </div>
        <div v-if="i === 2">
          <StartedMatcheComponent :active="tabs === i"/>
        </div>
        <div v-if="i === 3">
          <FinishMatcheComponent :active="tabs === i"/>
        </div>
      </v-window-item>
    </v-window>
    <BottomNavigationComponent />
  </section>
</template>
<script>
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent";
import ScheduledMatcheComponent from "@/components/Matche/ScheduledMatcheComponent";
import StartedMatcheComponent from "@/components/Matche/StartedMatcheComponent";
import FinishMatcheComponent from "@/components/Matche/FinishMatcheComponent";

export default {
  name: 'MatcheView',
  components: {FinishMatcheComponent, StartedMatcheComponent, ScheduledMatcheComponent, BottomNavigationComponent},
  data() {
    return {
      tabs: 0
    }
  }
}
</script>
<style scoped>
#match {
  height: 100vh !important;
}
</style>