<template>
  <section id="reset-password" class="bg-blue-darken-4">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/login" textBreadcrumbs="Changer votre mot de passe"/>
    <div style="padding-top: 80px">
    <v-img
        class="ma-auto pt-16"
        :width="300"
        aspect-ratio="1/1"
        :cover="true"
        :src="require('@/assets/images/background-connection-page.png')"
    ></v-img>
    <h4 class="text-center">Réinitialisez votre mot de passe ici !</h4>
    <v-sheet class="bg-blue-darken-4 mx-auto d-flex align-center justify-center flex-column">
      <v-form class="w-75 mt-10">
        <div class="bg-white px-5 pt-8 pb-3 mb-5 rounded">
          <v-text-field
              variant="solo"
              v-model="password" :error-messages="v$.password.$errors.map(e => e.$message)" @input="v$.password.$touch" @blur="v$.password.$touch"
              :type="typeFieldPassword"
              autocomplete="on"
              @click:appendInner="showPassword()"
              :append-inner-icon="showPasswordIcon ? 'mdi-eye-off' : 'mdi-eye'"
          >            <template v-slot:label>
            Nouveau mot de passe<span class="text-error ml-1"> *</span>
          </template></v-text-field>
          <v-text-field
              variant="solo"
              v-model="passwordConfirm" :error-messages="v$.passwordConfirm.$errors.map(e => e.$message)" @input="v$.passwordConfirm.$touch" @blur="v$.passwordConfirm.$touch"
              :type="typeFieldPasswordConfirm"
              autocomplete="on"
              @click:appendInner="showPasswordConfirm()"
              :append-inner-icon="showPasswordConfirmIcon ? 'mdi-eye-off' : 'mdi-eye'"
          >
            <template v-slot:label>
              Confirmer mot de passe<span class="text-error ml-1"> *</span>
            </template>
          </v-text-field>
        </div>
        <p class="mb-8 text-center">Entrez un nouveau mot de passe pour votre compte !</p>
        <v-btn :loading="loadingResetPasswordBtn" size="x-large" color="white" class="font-weight-bold d-flex ma-auto justify-center align-content-center align-center" @click="sendResetPassword()">Modifier</v-btn>
      </v-form>
    </v-sheet>
    </div>
  </section>
</template>
<script>
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import { useVuelidate } from '@vuelidate/core'
import {helpers, required, sameAs} from '@vuelidate/validators'
import {snackbarStore} from "@/store/snackbarStore";
import {userStore} from "@/store/userStore";

export default {
  name: 'ResetPasswordView',
  components: {BreadcrumbsComponent},
  data() {
    return {
      password: null,
      passwordConfirm: null,
      typeFieldPassword: "password",
      typeFieldPasswordConfirm: "password",
      showPasswordIcon: false,
      showPasswordConfirmIcon: false,
      loadingResetPasswordBtn: false
    }
  },
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      password: {
        required: helpers.withMessage('Le mot de passe est requis.', required),
        minLength: helpers.withMessage('Le mot de passe est trop court.', (value) => value.length >= 8),
        maxLength: helpers.withMessage('Le mot de passe est trop long.', (value) => value.length <= 255)
      },
      passwordConfirm: {
        required: helpers.withMessage('La confirmation du mot de passe est requise.', required),
        sameAsPassword: helpers.withMessage('Les mots de passe ne correspondent pas.', sameAs(this.password)),
        minLength: helpers.withMessage('Le mot de passe est trop court.', (value) => value.length >= 8),
        maxLength: helpers.withMessage('Le mot de passe est trop long.', (value) => value.length <= 255)
      },
    }
  },
  methods: {
    showPassword() {
      this.showPasswordIcon = !this.showPasswordIcon;
      this.typeFieldPassword === "text" ? this.typeFieldPassword = "password" : this.typeFieldPassword = "text";
    },
    showPasswordConfirm() {
      this.showPasswordConfirmIcon = !this.showPasswordConfirmIcon;
      this.typeFieldPasswordConfirm === "text" ? this.typeFieldPasswordConfirm = "password" : this.typeFieldPasswordConfirm = "text";
    },
    async sendResetPassword() {
      this.loadingResetPasswordBtn = true;
      const result = await this.v$.$validate()
      if (!result) {
        this.loadingResetPasswordBtn = false;
        return false;
      }
      if(this.$route.query.token) {
        await userStore().resetPassword(this.$route.query.token, this.password);
      } else {
        snackbarStore().showNotification("Le jeton de réinitialisation du mot de passe est vide !", true, "red-darken-1", 5000);
      }
      this.loadingResetPasswordBtn = false;
    }
  }
}
</script>
<style scoped>
#reset-password {
  height: 100vh;
}
:deep(.v-label) {
  opacity: 1 !important;
}
:deep(.v-messages) {
  text-align: left !important;
}
</style>
