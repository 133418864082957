<template>
  <section id="edit-info" class="bg-white">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/menu/setting" textBreadcrumbs="Modifier Apparence Profil"/>
    <v-container style="padding-top: 90px;">
      <v-file-input clearable prepend-inner-icon="mdi-emoticon" prepend-icon="" variant="solo" v-model="avatarImg" :error-messages="v$.avatarImg.$errors.map(e => e.$message)" @input="v$.avatarImg.$touch" @blur="v$.avatarImg.$touch">
        <template v-slot:label>
          Avatar<span class="text-error ml-1"> *</span>
        </template>
      </v-file-input>
      <div class="my-5 d-flex justify-end">
        <v-btn size="large" :loading="loadingEditInfoBtn" class="font-weight-bold" color="green-darken-1" @click="uploadFile('AVATAR', avatarImg)">Enregistrer</v-btn>
      </div>
      <v-file-input clearable prepend-inner-icon="mdi-clipboard-account-outline" prepend-icon="" variant="solo" v-model="backgroundImg" :error-messages="v$.backgroundImg.$errors.map(e => e.$message)" @input="v$.backgroundImg.$touch" @blur="v$.backgroundImg.$touch">
        <template v-slot:label>
          Fond du profil<span class="text-error ml-1"> *</span>
        </template>
      </v-file-input>
      <div class="my-5 d-flex justify-end">
        <v-btn size="large" :loading="loadingEditInfoBtn" class="font-weight-bold" color="green-darken-1" @click="uploadFile('BACKGROUND', backgroundImg)">Enregistrer</v-btn>
      </div>
    </v-container>
    <BottomNavigationComponent />
  </section>
</template>

<script>
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent";
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import {useVuelidate} from "@vuelidate/core/dist/index.mjs";
import {helpers} from "@vuelidate/validators";
import {userStore} from "@/store/userStore";

const isImage = (value) => {
  if (!helpers.req(value)) return true;
  const allowedExtensions = ['png', 'jpg', 'jpeg', 'gif'];
  const extension = value.name.split('.').pop().toLowerCase();
  return allowedExtensions.includes(extension);
}

export default {
  name: 'EditImageProfileView',
  components: {BreadcrumbsComponent, BottomNavigationComponent},
  data() {
    return {
      avatarImg: null,
      backgroundImg: null,
      loadingEditInfoBtn: false
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      avatarImg: {
        isImage: helpers.withMessage("Les formats de fichiers autorisés sont jpg, jpeg, png et gif !", isImage),
      },
      backgroundImg: {
        isImage: helpers.withMessage("Les formats de fichiers autorisés sont jpg, jpeg, png et gif !", isImage),
      },
    }
  },
  methods: {
    uploadFile(type, file) {
      userStore().userUploadFile(type, file);

    }
  }
}
</script>
<style scoped>
#edit-info {
  height: 100vh;
}
</style>
