<template>
  <section id="list-menu">
    <v-list class="pa-0" lines="two">
      <v-list-item v-for="menu in list_menu" :key="menu.title" @click="goTo(menu.route, menu.disconnect)" :title="menu.title" :subtitle="menu.subtitle" class="text-left">
        <template v-slot:prepend>
          <v-icon :icon="menu.icon_left" class="icon-left"></v-icon>
        </template>
        <template v-slot:append>
          <v-icon :icon="menu.icon_right" class="icon-right"></v-icon>
        </template>
      </v-list-item>
    </v-list>
  </section>
</template>
<script>
export default {
  name: 'ListMenuComponent',
  props: {
    list_menu: {
      type: Array,
      default: function () {
        return [
          {
            title: "Scores",
            value: null,
            icon_left: "mdi-soccer",
            subtitle: "Matchs et classements groupes",
            icon_right: "mdi-chevron-right",
            color: "black",
            route: "menu/scores"
          },
          {
            title: "Réglages",
            value: null,
            icon_left: "mdi-cog-outline",
            subtitle: "Mon compte, mes alertes",
            icon_right: "mdi-chevron-right",
            color: "black",
            route: "menu/setting"
          },
          {
            title: "Règlements",
            value: null,
            icon_left: "mdi-ruler",
            subtitle: "Les règles du jeu",
            icon_right: "mdi-chevron-right",
            color: "black",
            route: "menu/rules"
          },
          {
            title: "Foire aux Questions",
            value: null,
            icon_left: "mdi-help-circle-outline",
            subtitle: "Questions réponses",
            icon_right: "mdi-chevron-right",
            color: "black",
            route: "menu/faq"
          }
        ]
      }
    }
  },
  methods: {
    goTo(route) {
      this.$router.push(route)
    }
  }
}
</script>
<style scoped>
.icon-left {
  padding: 20px;
  color: #143CDB !important;
  opacity: 1;
  margin-right: 15px;
  border-radius: 50px;
  background: rgba(20, 60, 219, 0.1);
}
.icon-right {
  padding: 20px;
  color: #143CDB !important;
  opacity: 1;
}
</style>