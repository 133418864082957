import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import RegisterView from "@/views/Connection/RegisterView";
import Error404View from "@/views/Error/Error404View";
import ForgotPasswordView from "@/views/Connection/ForgotPasswordView";
import ResetPasswordView from "@/views/Connection/ResetPasswordView";
import LoginView from "@/views/Connection/LoginView";
import MenuView from "@/views/Menu/MenuView";
import SettingView from "@/views/Menu/SettingView";
import EditInfoView from "@/views/Menu/Setting/EditInfoView";
import EditPasswordView from "@/views/Menu/Setting/EditPasswordView";
import {userStore} from "@/store/userStore";
import MatcheView from "@/views/Matche/MatcheView";
import LeadboardView from "@/views/Leadboard/LeadboardView";
import ProfileView from "@/views/Profile/ProfileView";
import FaqView from "@/views/Menu/FaqView";
import ScoreView from "@/views/Menu/ScoreView";
import RulesView from "@/views/Menu/RulesView";
import EditImageProfileView from "@/views/Menu/Setting/EditImageProfileView";
import {snackbarStore} from "@/store/snackbarStore";
import {leadboardStore} from "@/store/leadboardStore";
import EditNotificationView from "@/views/Menu/Setting/EditNotificationView";
import {fixtureStore} from "@/store/fixtureStore";
import AdminView from "@/views/Admin/AdminView";
import TchatView from "@/views/Tchat/TchatView";

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next('/menu');
      } else {
        next();
      }
    }
  },
  {
    path: '/login',
    name: 'Se connecter',
    component: LoginView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next('/menu');
      } else {
        next();
      }
    }
  },
  {
    path: '/register',
    name: 'S\'inscrire',
    component: RegisterView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next('/menu');
      } else {
        next();
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'Mot de passe oublié',
    component: ForgotPasswordView,
  },
  {
    path: '/reset-password',
    name: 'Réinitialisation mot de passe',
    component: ResetPasswordView,
  },
  {
    path: '/menu',
    name: 'Menu',
    component: MenuView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/menu/scores',
    name: 'Scores',
    component: ScoreView,
    beforeEnter: async (to, from, next) => {
      if (userStore().isConnected) {
        try {
          await fixtureStore().callAllInfoTeam();
        } catch (error) {
          snackbarStore().showNotification(error, true, "red-darken-1", 5000);
        }
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/menu/rules',
    name: 'Règlements',
    component: RulesView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/menu/faq',
    name: 'Faq',
    component: FaqView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/menu/setting',
    name: 'Réglages',
    component: SettingView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/menu/setting/info',
    name: 'Modifier Infos',
    component: EditInfoView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/menu/setting/password',
    name: 'Modifier Mot de passe',
    component: EditPasswordView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/menu/setting/image',
    name: 'Modifier Apparence Profil',
    component: EditImageProfileView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/menu/setting/notification',
    name: 'Modifier Notification',
    component: EditNotificationView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/matche',
    name: 'Match',
    component: MatcheView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/leadboard',
    name: 'Classement',
    component: LeadboardView,
    beforeEnter: async (to, from, next) => {
      if (userStore().isConnected) {
        try {
          await leadboardStore().callGetLeadboard();
        } catch (error) {
          snackbarStore().showNotification(error, true, "red-darken-1", 5000);
        }
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/profile',
    name: 'Profil',
    component: ProfileView,
    beforeEnter: async (to, from, next) => {
      if (userStore().isConnected) {
        try {
          await userStore().userInfo("me");
        } catch (error) {
          snackbarStore().showNotification(error, true, "red-darken-1", 5000);
        }
        next();
      } else {
        next('/login');
      }
    }
  },
  {    path: '/tchat',
    name: 'Tchat',
    component: TchatView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView,
    beforeEnter: (to, from, next) => {
      if (userStore().isConnected) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404 Erreur',
    component: Error404View
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach(() => {
  userStore().userInfo("me");
});
export default router
