<template>
  <section id="leadboard" class="bg-white">
    <v-tabs v-model="tabs"  class="position-fixed w-100" bg-color="blue-darken-4" style="z-index: 9999;" grow>
      <v-tab :value="1" class="font-weight-bold">GÉNÉRAL</v-tab>
    </v-tabs>
    <v-window v-model="tabs" class="pt-12 bg-white" style="padding-bottom: 100px;">
      <v-window-item v-for="i in 1" :key="i" :value="i">
        <div v-if="i === 1" class="bg-white">
          <v-row style="touch-action: manipulation;" no-gutters class="position-fixed w-100 py-6 text-center d-flex justify-center" id="rowTableHeader">
            <v-col cols="2" style="font-size: 10px">
              <v-icon icon="mdi-trophy" color="blue-darken-4"></v-icon>
              <p>Position</p>
            </v-col>
            <v-col cols="4" style="font-size: 10px">
              <v-icon icon="mdi-account" color="blue-darken-4"></v-icon>
              <p>Joueurs</p>
            </v-col>
            <v-col cols="1" style="font-size: 10px">
              <v-icon icon="mdi-star-circle" color="yellow"></v-icon>
              <p>E</p>
            </v-col>
            <v-col cols="1" style="font-size: 10px">
              <v-icon icon="mdi-check-circle" color="green"></v-icon>
              <p>G</p>
            </v-col>
            <v-col cols="1" style="font-size: 10px">
              <v-icon icon="mdi-close-circle" color="red"></v-icon>
              <p>P</p>
            </v-col>
            <v-col cols="1" style="font-size: 10px">
              <v-icon icon="mdi-close-circle" color="black"></v-icon>
              <p>NP</p>
            </v-col>
            <v-col cols="2" style="font-size: 10px">
              <v-icon icon="mdi-alpha-s-circle" color="orange"></v-icon>
              <p>Scores</p>
            </v-col>
          </v-row>
          <div v-if="getDataLeadboard.length <= 0" class="center-container">
            <v-empty-state
                icon="mdi-emoticon-sad-outline"
                text="Il n'y a pour l'instant aucun classement !"
                title="Aucun classement"
            ></v-empty-state>
          </div>
          <div v-else style="padding-top: 80px" class="bg-white">
            <div v-for="player in getDataLeadboard" v-bind:key="player.id" class="showInfosUser">
              <div v-if="getUserInfos.id === player.id">
                <v-row style="touch-action: manipulation;" no-gutters class="py-5 pt-0 text-center justify-center align-center mePosition" @click="openUserInfos(player.id)">
                  <v-col cols="12">
                    <p class="text-caption">Ma position</p>
                  </v-col>
                  <v-col cols="2">
                    #{{ player.position }}
                    <template v-if="player.oldPosition !== null">
                      <v-icon color="grey-darken-1" v-if="player.position === player.oldPosition" icon="mdi-equal"></v-icon>
                      <v-icon color="red" v-else-if="player.position > player.oldPosition" icon="mdi-trending-down"></v-icon>
                      <v-icon color="green" v-else icon="mdi-trending-up"></v-icon>
                    </template>
                  </v-col>
                  <v-col cols="4" style="font-size: 12px" class="d-flex justify-space-evenly align-self-center align-center">
                    <v-avatar class="mr-1" size="25" v-if="player.avatarUrl != null">
                      <v-img
                          v-if="player.avatarUrl != null"
                          :src="linkApi + 'users/avatar/' + player.avatarUrl"
                      >
                        <template v-slot:placeholder>
                          <v-progress-circular :size="20" indeterminate></v-progress-circular>
                        </template>
                      </v-img>
                    </v-avatar>
                    <v-avatar class="mr-1" :size="25" color="yellow-darken-2" v-else >
                      {{ player.name.slice(0, 2).toUpperCase() }}
                    </v-avatar>
                    {{ player.name }}
                  </v-col>
                  <v-col cols="1">
                    {{ player.perfectWinBet }}
                  </v-col>
                  <v-col cols="1">
                    {{ player.winBet }}
                  </v-col>
                  <v-col cols="1">
                    {{ player.lostBet }}
                  </v-col>
                  <v-col cols="1">
                    {{ player.nullBet }}
                  </v-col>
                  <v-col cols="2" class="font-weight-bold">
                    {{ player.point }}
                  </v-col>
                </v-row>
              </div>
              <div v-else>
              <v-row style="touch-action: manipulation;" v-if="player.position === 1" no-gutters class="py-5 text-center justify-center align-center firstLeadboard" @click="openUserInfos(player.id)">
                <v-col cols="2">
                  #{{ player.position }}
                  <template v-if="player.oldPosition !== null">
                  <v-icon color="grey-darken-1" v-if="player.position === player.oldPosition" icon="mdi-equal"></v-icon>
                  <v-icon color="red" v-else-if="player.position > player.oldPosition" icon="mdi-trending-down"></v-icon>
                  <v-icon color="green" v-else icon="mdi-trending-up"></v-icon>
                  </template>
                </v-col>
                <v-col cols="4" style="font-size: 12px" class="d-flex justify-space-evenly align-self-center align-center">
                  <v-avatar class="mr-1" size="25" v-if="player.avatarUrl != null">
                    <v-img
                        v-if="player.avatarUrl != null"
                        :src="linkApi + 'users/avatar/' + player.avatarUrl"
                    >
                      <template v-slot:placeholder>
                        <v-progress-circular :size="20" indeterminate></v-progress-circular>
                      </template>
                    </v-img>
                  </v-avatar>
                  <v-avatar class="mr-1" :size="25" color="yellow-darken-2" v-else >
                    {{ player.name.slice(0, 2).toUpperCase() }}
                  </v-avatar>
                  {{ player.name }}
                </v-col>
                <v-col cols="1">
                  {{ player.perfectWinBet }}
                </v-col>
                <v-col cols="1">
                  {{ player.winBet }}
                </v-col>
                <v-col cols="1">
                  {{ player.lostBet }}
                </v-col>
                <v-col cols="1">
                  {{ player.nullBet }}
                </v-col>
                <v-col cols="2" class="font-weight-bold">
                  {{ player.point }}
                </v-col>
              </v-row>
              <v-row style="touch-action: manipulation;" v-if="player.position === 2" no-gutters class="py-5 text-center justify-center align-center secondLeadboard" @click="openUserInfos(player.id)">
                <v-col cols="2">
                  #{{ player.position }}
                  <template v-if="player.oldPosition !== null">
                    <v-icon color="grey-darken-1" v-if="player.position === player.oldPosition" icon="mdi-equal"></v-icon>
                    <v-icon color="red" v-else-if="player.position > player.oldPosition" icon="mdi-trending-down"></v-icon>
                    <v-icon color="green" v-else icon="mdi-trending-up"></v-icon>
                  </template>
                </v-col>
                <v-col cols="4" style="font-size: 12px" class="d-flex justify-space-evenly align-self-center align-center">
                  <v-avatar class="mr-1" size="25" v-if="player.avatarUrl != null">
                    <v-img
                        v-if="player.avatarUrl != null"
                        :src="linkApi + 'users/avatar/' + player.avatarUrl"
                    >
                      <template v-slot:placeholder>
                        <v-progress-circular :size="20" indeterminate></v-progress-circular>
                      </template>
                    </v-img>
                  </v-avatar>
                  <v-avatar class="mr-1" :size="25" color="grey-lighten-1" v-else >
                    {{ player.name.slice(0, 2).toUpperCase() }}
                  </v-avatar>
                  {{ player.name }}
                </v-col>
                <v-col cols="1">
                  {{ player.perfectWinBet }}
                </v-col>
                <v-col cols="1">
                  {{ player.winBet }}
                </v-col>
                <v-col cols="1">
                  {{ player.lostBet }}
                </v-col>
                <v-col cols="1">
                  {{ player.nullBet }}
                </v-col>
                <v-col cols="2" class="font-weight-bold">
                  {{ player.point }}
                </v-col>
              </v-row>
              <v-row style="touch-action: manipulation;" v-if="player.position === 3" no-gutters class="py-5 text-center justify-center align-center thirdLeadboard" @click="openUserInfos(player.id)">
                <v-col cols="2">
                  #{{ player.position }}
                  <template v-if="player.oldPosition !== null">
                    <v-icon color="grey-darken-1" v-if="player.position === player.oldPosition" icon="mdi-equal"></v-icon>
                    <v-icon color="red" v-else-if="player.position > player.oldPosition" icon="mdi-trending-down"></v-icon>
                    <v-icon color="green" v-else icon="mdi-trending-up"></v-icon>
                  </template>
                </v-col>
                <v-col cols="4" style="font-size: 12px" class="d-flex justify-space-evenly align-self-center align-center">
                  <v-avatar class="mr-1" size="25" v-if="player.avatarUrl != null">
                    <v-img
                        v-if="player.avatarUrl != null"
                        :src="linkApi + 'users/avatar/' + player.avatarUrl"
                    >
                      <template v-slot:placeholder>
                        <v-progress-circular :size="20" indeterminate></v-progress-circular>
                      </template>
                    </v-img>
                  </v-avatar>
                  <v-avatar class="mr-1" size="25" color="orange-accent-2" v-else >
                    {{ player.name.slice(0, 2).toUpperCase() }}
                  </v-avatar>
                  {{ player.name }}
                </v-col>
                <v-col cols="1">
                  {{ player.perfectWinBet }}
                </v-col>
                <v-col cols="1">
                  {{ player.winBet }}
                </v-col>
                <v-col cols="1">
                  {{ player.lostBet }}
                </v-col>
                <v-col cols="1">
                  {{ player.nullBet }}
                </v-col>
                <v-col cols="2" class="font-weight-bold">
                  {{ player.point }}
                </v-col>
              </v-row>
              <v-row style="touch-action: manipulation;" v-if="player.position !== 1 && player.position !== 2 && player.position !== 3" no-gutters class="py-5 text-center justify-center align-center" @click="openUserInfos(player.id)">
                <v-col cols="2">
                  #{{ player.position }}
                  <template v-if="player.oldPosition !== null">
                    <v-icon color="grey-darken-1" v-if="player.position === player.oldPosition" icon="mdi-equal"></v-icon>
                    <v-icon color="red" v-else-if="player.position > player.oldPosition" icon="mdi-trending-down"></v-icon>
                    <v-icon color="green" v-else icon="mdi-trending-up"></v-icon>
                  </template>
                </v-col>
                <v-col cols="4" style="font-size: 12px" class="d-flex justify-space-evenly align-self-center align-center">
                  <v-avatar class="mr-1" size="25" v-if="player.avatarUrl != null">
                    <v-img
                        v-if="player.avatarUrl != null"
                        :src="linkApi + 'users/avatar/' + player.avatarUrl"
                    >
                      <template v-slot:placeholder>
                        <v-progress-circular :size="20" indeterminate></v-progress-circular>
                      </template>
                    </v-img>
                  </v-avatar>
                  <v-avatar class="mr-1" size="25" color="blue-grey-lighten-5" v-else >
                    {{ player.name.slice(0, 2).toUpperCase() }}
                  </v-avatar>
                  {{ player.name }}
                </v-col>
                <v-col cols="1">
                  {{ player.perfectWinBet }}
                </v-col>
                <v-col cols="1">
                  {{ player.winBet }}
                </v-col>
                <v-col cols="1">
                  {{ player.lostBet }}
                </v-col>
                <v-col cols="1">
                  {{ player.nullBet }}
                </v-col>
                <v-col cols="2" class="font-weight-bold">
                  {{ player.point }}
                </v-col>
              </v-row>
              </div>
            </div>
          </div>
        </div>
      </v-window-item>
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-content-center align-center flex-column">
          <p><v-icon icon="mdi-star-circle" color="yellow"></v-icon> E : Paris Parfaits</p>
          <p><v-icon icon="mdi-check-circle" color="green"></v-icon> G : Paris Réussis</p>
          <p><v-icon icon="mdi-close-circle" color="red"></v-icon> P : Paris Perdus</p>
          <p><v-icon icon="mdi-close-circle" color="black"></v-icon> NP : Paris Non Pariés</p>
          <p><v-icon color="green" icon="mdi-trending-up"></v-icon> : Vous avez gagné des places</p>
          <p><v-icon color="red" icon="mdi-trending-down"></v-icon> : Vous avez perdu des places</p>
          <p><v-icon color="grey-darken-1" icon="mdi-equal"></v-icon> : Vous êtes toujours à la même place</p>
        </v-col>
      </v-row>
    </v-window>
    <UserInfosComponent :openDialogUserInfos="openDialogUserInfos" />
    <BottomNavigationComponent />
  </section>
</template>
<script>
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent";
import {leadboardStore} from "@/store/leadboardStore";
import UserInfosComponent from "@/components/Leadboard/UserInfosComponent";
import {userStore} from "@/store/userStore";

export default {
  name: 'LeadboardView',
  components: {UserInfosComponent, BottomNavigationComponent},
  data() {
    return {
      tabs: 0,
      openDialogUserInfos: false,
      linkApi: process.env.VUE_APP_API_URL_PUBLIC
    }
  },
  computed: {
    getUserInfos() {
      return userStore().getUserInfos;
    },
    getDataLeadboard() {
      return leadboardStore().getDataLeadboard;
    }
  },
  methods: {
    async openUserInfos(idUser) {
      if (await userStore().userInfo(idUser)) {
        leadboardStore().showDialogUserInfos = true;
      }
    }
  },
}
</script>
<style scoped>
#rowTableHeader {
  z-index: 1;
  background-color: white;
}
#leadboard {
  height: 100vh !important;
}
.firstLeadboard {
  background: #FFD700;
}
.secondLeadboard {
  background: #C0C0C0;
}
.thirdLeadboard {
  background: #CD7F32;
}
.thirdLeadboard:hover, .secondLeadboard:hover, .firstLeadboard:hover {
  background: #797271;
}
.mePosition {
  background: #96c6f7;
}
.showInfosUser:hover {
  cursor: pointer;
  background: #797271;
}
</style>