<template>
  <section id="scores" class="bg-white">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/menu" textBreadcrumbs="Scores"/>
    <v-container style="padding: 80px 10px;">
      <div class="d-flex">
        <v-select
            :items="selectScores"
            variant="solo"
            menu-icon="mdi-chevron-down"
            item-title="text"
            item-value="value"
            v-model="itemsSelect"
        >
          <template v-slot:prepend>
            <v-btn style="touch-action: none;" icon="mdi-chevron-left" @click="selectScoresLeft()"></v-btn>
          </template>
          <template v-slot:append>
            <v-btn style="touch-action: none;" icon="mdi-chevron-right" @click="selectScoresRight()"></v-btn>
          </template>
        </v-select>
      </div>
      <h3 class="mb-5 text-center" v-if="filteredLeadboard.length > 0">
        Classement {{ selectPoolForAPI(itemsSelect) }}
      </h3>
      <v-card class="pa-2" v-if="filteredLeadboard.length > 0" style="font-size: 14px">
        <v-row :no-gutters="true" class="text-center">
          <v-col cols="4">
            Équipe
          </v-col>
          <v-col cols="1">
            G
          </v-col>
          <v-col cols="1">
            N
          </v-col>
          <v-col cols="1">
            P
          </v-col>
          <v-col cols="1">
            BP
          </v-col>
          <v-col cols="1">
            BC
          </v-col>
          <v-col cols="1">
            DB
          </v-col>
          <v-col cols="2">
            Pts
          </v-col>
        </v-row>
        <v-row :no-gutters="true" v-for="(leadboard, index) in filteredLeadboard" :key="leadboard" class="text-center py-2">
          <v-col cols="1">
            <v-icon v-if="(index + 1) === 1" icon="mdi-numeric-1-circle"></v-icon>
            <v-icon v-if="(index + 1) === 2" icon="mdi-numeric-2-circle"></v-icon>
            <v-icon v-if="(index + 1) === 3" icon="mdi-numeric-3-circle"></v-icon>
            <v-icon v-if="(index + 1) === 4" icon="mdi-numeric-4-circle"></v-icon>
          </v-col>
          <v-col cols="3" class="text-left d-flex justify-space-between align-center">
            {{ leadboard.name }}
            <country-flag :rounded="true" :country="leadboard.isoFlag" size='small' style="box-shadow: rgba(227,227,227,0.99) 0px 0px 3px, rgba(227,227,227,0.99) 0px 0px 0px 3px;"/>
          </v-col>
          <v-col cols="1">
            {{leadboard.victory}}
          </v-col>
          <v-col cols="1">
            {{leadboard.draw}}
          </v-col>
          <v-col cols="1">
            {{leadboard.defeat}}
          </v-col>
          <v-col cols="1">
            {{leadboard.goalScore}}
          </v-col>
          <v-col cols="1">
            {{leadboard.goalAgainst}}
          </v-col>
          <v-col cols="1">
            {{leadboard.goalDifference}}
          </v-col>
          <v-col cols="2">
            {{leadboard.point}}
          </v-col>
        </v-row>
      </v-card>
      <h3 class="mb-5 mt-5 text-center" v-if="filteredLeadboard.length > 0">Matchs {{ selectPoolForAPI(itemsSelect) }}</h3>
      <div v-else>
        <h3 class="mb-5 text-center" v-if="itemsSelect === 'Finale'">Match {{ selectPoolForAPI(itemsSelect) }}</h3>
        <h3 class="mb-5 text-center" v-else>Matchs {{ selectPoolForAPI(itemsSelect) }}</h3>
      </div>
      <v-card class="pa-2">
        <v-row no-gutters v-for="fixture in filteredFixtures" :key="fixture">
          <v-col cols="4" class="d-flex justify-start align-content-center align-center" v-if="fixture.team1.name != null">
            <country-flag :rounded="true" :country="fixture?.team1?.isoFlag" size='small' style="box-shadow: rgba(227,227,227,0.99) 0px 0px 3px, rgba(227,227,227,0.99) 0px 0px 0px 3px;"/><span class="ml-3">{{fixture?.team1?.name}}</span>
          </v-col>
          <v-col cols="4" class="d-flex justify-start align-content-center align-center" v-else>
            <v-icon icon="mdi-help-box"></v-icon><span class="ml-3">En attente</span>
          </v-col>
          <v-col cols="4" class="text-center">
            <span class="mr-3">{{fixture.goalTeam1}}</span>-<span class="ml-3">{{fixture.goalTeam2}}</span>
          </v-col>
          <v-col cols="4" class="d-flex justify-end align-content-center align-center" v-if="fixture.team2.name !== null">
            <span class="mr-3">{{fixture?.team2?.name}}</span><country-flag :rounded="true" :country="fixture?.team2?.isoFlag" size='small' style="box-shadow: rgba(227,227,227,0.99) 0px 0px 3px, rgba(227,227,227,0.99) 0px 0px 0px 3px;"/>
          </v-col>
          <v-col cols="4" class="d-flex justify-end align-content-center align-center" v-else>
            <span class="mr-3">En attente</span><v-icon icon="mdi-help-box"></v-icon>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <BottomNavigationComponent />
  </section>
</template>

<script>
import {fixtureStore} from "@/store/fixtureStore";
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent";
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";

export default {
  components: {BottomNavigationComponent, BreadcrumbsComponent},
  data() {
    return {
      itemsSelect: "A",
      selectScores: [
        { text: 'Groupe A', value: 'A' },
        { text: 'Groupe B', value: 'B' },
        { text: 'Groupe C', value: 'C' },
        { text: 'Groupe D', value: 'D' },
        { text: 'Groupe E', value: 'E' },
        { text: 'Groupe F', value: 'F' },
        { text: '8ème de Finale', value: '1/8' },
        { text: 'Quart de Finale', value: '1/4' },
        { text: 'Demi-Finale', value: '1/2' },
        { text: 'Finale', value: 'Finale' }
      ]
    };
  },
  computed: {
    filteredFixtures() {
      return this.itemsSelect && fixtureStore().getAllInfoTeam[this.itemsSelect]?.fixture || [];
    },
    filteredLeadboard() {
      return this.itemsSelect && fixtureStore().getAllInfoTeam[this.itemsSelect]?.leadboard || [];
    },
  },
  methods: {
    selectPoolForAPI(name) {
      switch (name) {
        case 'A':

          return 'Groupe A'
        case 'B':

          return 'Groupe B'
        case 'C':

          return 'Groupe C'
        case 'D':

          return 'Groupe D'
        case 'E':

          return 'Groupe E'
        case 'F':

          return 'Groupe F'
        case '1/8':

          return '8ème de Finale'
        case '1/4':

          return 'Quart de Finale'
        case '1/2':

          return 'Demi-Finale'
        case 'Finale':

          return 'Finale'
        default:
          return false;
      }
    },
    selectScoresLeft() {
      const currentIndex = this.selectScores.findIndex(item => item.value === this.itemsSelect);
      if (currentIndex === 0) {
        this.itemsSelect = this.selectScores[this.selectScores.length - 1].value;
      } else {
        this.itemsSelect = this.selectScores[currentIndex - 1].value;
      }
    },
    selectScoresRight() {
      const currentIndex = this.selectScores.findIndex(item => item.value === this.itemsSelect);
      if (currentIndex === this.selectScores.length - 1) {
        this.itemsSelect = this.selectScores[0].value;
      } else {
        this.itemsSelect = this.selectScores[currentIndex + 1].value;
      }
    },
  }
};
</script>
<style scoped>
#scores {
  height: 100vh !important;
}
</style>