<template>
    <router-view/>
    <SnackbarsComponent />
</template>
<script>
import SnackbarsComponent from "@/components/General/SnackbarsComponent";
export default {
  name: 'App',
  components: {SnackbarsComponent}
}
</script>
<style>
@font-face {
  font-family: 'Manrope-Regular';
  src: url('~@/assets/fonts/Manrope-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Manrope-Bold';
  src: url('~@/assets/fonts/Manrope-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Manrope-ExtraLight';
  src: url('~@/assets/fonts/Manrope-ExtraLight.ttf') format('truetype');
}
html, body {
  background-color: #143CDB;
  font-family: Manrope-Regular, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>