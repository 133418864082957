<template>
  <section id="menu" class="bg-white">
    <ListMenuComponent />
    <div class="position-fixed versionInfos">
      <!--<div class="d-flex justify-center align-content-center align-center">
      <video width="50%" height="150" class="ma-auto" controls>
        <source :src="require('@/assets/videos/trailer.mp4')" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      </div>-->
      <p class="text-center">Un soucis ? Un bug ?</p>
      <p class="text-center">Envoyez un email :</p>
      <h3 class="text-center mb-2">contact@pariosa.fr</h3>
      <v-btn size="large" class="mb-5 ma-auto d-flex font-weight-bold" color="red-darken-1" @click="sendLogout()">Se déconnecter</v-btn>
      <p class="text-center text-caption mt-5 mb-0 font-weight-regular">Version Dracaufeu<span class="font-weight-black"> 1.0.5</span></p>
      <v-img class="ma-auto" :src="imgVersion " :width="40" aspect-ratio="1/1"></v-img>
      <div class="d-flex justify-center align-center align-content-center">
        <v-btn size="x-small" @click="refreshUpdate" class="mt-2 font-weight-bold" color="blue-darken-4" prepend-icon="mdi-update">Vérifier MàJ</v-btn>
        <v-btn size="x-small" v-if="hasRole('ROLE_ADMIN_PARIOSA')" @click="goTo('admin')" color="blue-darken-4" class="mt-2 ml-2 font-weight-bold" prepend-icon="mdi-shield-crown">Admin Panel</v-btn>
      </div>
    </div>
    <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        fullscreen
    >
      <v-card>

      </v-card>
    </v-dialog>
    <BottomNavigationComponent />
  </section>
</template>

<script>
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent";
import {userStore} from "@/store/userStore";
import ListMenuComponent from "@/components/General/ListMenuComponent";
import router from "@/router";
export default {
  name: 'MenuView',
  components: {ListMenuComponent, BottomNavigationComponent},
  methods: {
    hasRole(role) {
      return this.userRoles.includes(role);
    },
    sendLogout() {
      userStore().logout();
    },
    goTo(link) {
      router.push(link);
    },
    refreshUpdate() {
      window.location.reload(true);
    },
  },
  data() {
    return {
      imgVersion: null
    }
  },
  created() {
    this.userRoles = userStore().getUserRoles;
  },
  mounted() {
    var Pokedex = require('pokedex'), pokedex = new Pokedex();
    this.imgVersion = pokedex.pokemon(parseInt(6)).sprites.animated;
  }
}
</script>
<style scoped>
::v-deep(.v-list-item__prepend) {
  display: inline !important;
}
#menu {
  height: 100vh;
}
.versionInfos {
  bottom: 100px !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
}
</style>
