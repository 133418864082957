<template>
  <section id="error-404" class="position-relative bg-blue-darken-4">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/" :linkPrevious="true" textBreadcrumbs="Retour" />
    <div style="padding-top: 80px">
      <v-img
          class="ma-auto pt-16"
          :width="300"
          aspect-ratio="1/1"
          :cover="true"
          :src="require('@/assets/images/background-connection-page.png')"
      ></v-img>
      <v-empty-state
          color="white"
          icon="mdi-emoticon-cry-outline"
          text="Page non trouvé ou bien supprimé !"
          title="Erreur 404"
      >
        <template v-slot:actions>
          <v-btn class="mt-5 font-weight-bold text-black" size="large" @click="returnPrevious()">Page précédente</v-btn>
          <v-btn class="mt-5 font-weight-bold text-black" size="large" @click="goTo('/')">Accueil</v-btn>
        </template>
      </v-empty-state>
    </div>
  </section>
</template>
<script>
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import router from "@/router";
export default {
  name: 'Error404View',
  components: {BreadcrumbsComponent},
  methods: {
    returnPrevious() {
      router.go(-1);
    },
    goTo(link) {
      router.push(link);
    },
  }
}
</script>
<style scoped>
#error-404 {
  height: 100vh;
}
.error-404-section {
  left: 0;
  right: 0;
  top: 11%;
  bottom: 0;
}
</style>