<template>
  <section id="home" class="position-relative bg-blue-darken-4">
    <v-carousel :hide-delimiters="true" :show-arrows="false" :cycle="true" class="background-home">
      <v-carousel-item :src="require('@/assets/images/background-home-page.png')" :cover="true"></v-carousel-item>
    </v-carousel>
    <div class="position-absolute ma-auto choice-panel">
      <div class="text-center d-flex justify-center align-center h-100 flex-column">
        <h1 class="text-white my-4">PARIOSA</h1>
        <v-btn class="mt-5 font-weight-bold text-black" size="x-large" @click="goTo('login')">Se connecter</v-btn>
        <v-btn class="mt-5 font-weight-bold text-black" size="x-large" @click="goTo('register')">S'inscrire</v-btn>
      </div>
    </div>
  </section>
</template>
<script>
import router from "@/router";

export default {
  name: 'HomeView',
  methods: {
    goTo(route) {
      router.push({path: route})
    }
  }
}
</script>
<style scoped>
.choice-panel {
  left: 0;
  right: 0;
  top: 52%;
  transform: translateY(-50%);
  bottom: 0;
}
.background-home {
  height: 100vh !important;
}
</style>