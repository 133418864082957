<template>
  <section id="edit-password" class="bg-white">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/menu/setting" textBreadcrumbs="Modifier Mot de passe"/>
    <v-container style="padding-top: 90px;">
      <v-text-field
          variant="solo"
          v-model="actualPassword" :error-messages="v$.actualPassword.$errors.map(e => e.$message)" @input="v$.actualPassword.$touch" @blur="v$.actualPassword.$touch"
          :type="typeFieldActualPassword"
          autocomplete="on"
          @click:appendInner="showActualPassword()"
          :append-inner-icon="showActualPasswordIcon ? 'mdi-eye-off' : 'mdi-eye'"
      >
        <template v-slot:label>
          Mot de passe actuel<span class="text-error ml-1"> *</span>
        </template>
      </v-text-field>
      <v-text-field
          variant="solo"
          v-model="newPassword" :error-messages="v$.newPassword.$errors.map(e => e.$message)" @input="v$.newPassword.$touch" @blur="v$.newPassword.$touch"
          :type="typeFieldNewPassword"
          autocomplete="on"
          @click:appendInner="showNewPassword()"
          :append-inner-icon="showNewPasswordIcon ? 'mdi-eye-off' : 'mdi-eye'"
      >            <template v-slot:label>
        Nouveau mot de passe<span class="text-error ml-1"> *</span>
      </template></v-text-field>
      <v-text-field
          variant="solo"
          v-model="confirmNewPassword" :error-messages="v$.confirmNewPassword.$errors.map(e => e.$message)" @input="v$.confirmNewPassword.$touch" @blur="v$.confirmNewPassword.$touch"
          :type="typeFieldNewPasswordConfirm"
          autocomplete="on"
          @click:appendInner="showNewPasswordConfirm()"
          :append-inner-icon="showNewPasswordConfirmIcon ? 'mdi-eye-off' : 'mdi-eye'"
      >            <template v-slot:label>
        Confirmer nouveau mot de passe<span class="text-error ml-1"> *</span>
      </template></v-text-field>
      <v-btn size="large" :loading="loadingEditPasswordBtn" class="mt-5 ma-auto d-flex font-weight-bold" color="green-darken-1" @click="sendEditPassword()">Enregistrer</v-btn>
      <p class="text-center text-decoration-underline mt-5 mb-10 cursor-text"><a @click="goTo('/forgot-password')">J'ai oublié mon mot de passe</a></p>
    </v-container>
    <BottomNavigationComponent />
  </section>
</template>

<script>
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent";
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import {useVuelidate} from "@vuelidate/core/dist/index.mjs";
import {helpers, required, sameAs} from "@vuelidate/validators";
import router from "@/router";
import {userStore} from "@/store/userStore";

export default {
  name: 'EditPasswordView',
  components: {BreadcrumbsComponent, BottomNavigationComponent},
  data() {
    return {
      actualPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      loadingEditPasswordBtn: false,
      typeFieldActualPassword: "password",
      typeFieldNewPassword: "password",
      typeFieldNewPasswordConfirm: "password",
      showActualPasswordIcon: false,
      showNewPasswordIcon: false,
      showNewPasswordConfirmIcon: false,
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      actualPassword: {
        required: helpers.withMessage("Le prénom est requis.", required),
      },
      newPassword: {
        required: helpers.withMessage('Le mot de passe est requis.', required),
        minLength: helpers.withMessage('Le mot de passe est trop court.', (value) => value.length >= 8),
        maxLength: helpers.withMessage('Le mot de passe est trop long.', (value) => value.length <= 255)
      },
      confirmNewPassword: {
        required: helpers.withMessage('La confirmation du mot de passe est requise.', required),
        sameAsPassword: helpers.withMessage('Les mots de passe ne correspondent pas.', sameAs(this.newPassword)),
        minLength: helpers.withMessage('Le mot de passe est trop court.', (value) => value.length >= 8),
        maxLength: helpers.withMessage('Le mot de passe est trop long.', (value) => value.length <= 255)
      },
    }
  },
  methods: {
    showActualPassword() {
      this.showActualPasswordIcon = !this.showActualPasswordIcon;
      this.typeFieldActualPassword === "text" ? this.typeFieldActualPassword = "password" : this.typeFieldActualPassword = "text";
    },
    showNewPassword() {
      this.showNewPasswordIcon = !this.showNewPasswordIcon;
      this.typeFieldNewPassword === "text" ? this.typeFieldNewPassword = "password" : this.typeFieldNewPassword = "text";
    },
    showNewPasswordConfirm() {
      this.showNewPasswordConfirmIcon = !this.showNewPasswordConfirmIcon;
      this.typeFieldNewPasswordConfirm === "text" ? this.typeFieldNewPasswordConfirm  = "password" : this.typeFieldNewPasswordConfirm  = "text";
    },
    goTo(link) {
      router.push(link);
    },
    async sendEditPassword() {
      this.loadingEditPasswordBtn = true;
      const result = await this.v$.$validate()
      if (!result) {
        this.loadingEditPasswordBtn = false;
        return false;
      }
      await userStore().editPassword(this.actualPassword, this.newPassword);
      this.loadingEditPasswordBtn = false;
    }
  }
}
</script>
<style scoped>
#edit-password {
  height: 100vh;
}
</style>
