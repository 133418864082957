<template>
  <section id="bottom-navigation">
    <v-layout>
      <v-bottom-navigation style="z-index: 9999999" bg-color="blue-darken-4" color="white" :grow="true" fixed class="font-weight-black height-bottom-navigation">
        <v-btn v-for="menu in getMenuRole" :key="menu.link" :to="menu.link">
          <div v-if="menu.link === '/matche'">
            <v-badge color="red" :content="getInfoMeUser.totalNoBet" :floating="true" v-if="getInfoMeUser.totalNoBet > 0 && getInfoMeUser.totalNoBet !== null">
              <v-icon>{{ menu.icon }}</v-icon>
            </v-badge>
            <v-icon v-else>{{ menu.icon }}</v-icon>
          </div>
          <div v-else-if="menu.link === '/leadboard'">
            <template v-if="getInfoMeUser && getInfoMeUser.score && typeof getInfoMeUser.score.position !== 'undefined' && getInfoMeUser.score.position !== null">
              <v-badge color="#FFD700" :content="getInfoMeUser.score.position + 'er'" :floating="true" v-if="getInfoMeUser.score.position === 1">
                <v-icon>{{ menu.icon }}</v-icon>
              </v-badge>
              <v-badge color="#C0C0C0" :content="getInfoMeUser.score.position + 'ème'" :floating="true" v-if="getInfoMeUser.score.position === 2">
                <v-icon>{{ menu.icon }}</v-icon>
              </v-badge>
              <v-badge color="#CD7F32" :content="getInfoMeUser.score.position + 'ème'" :floating="true" v-if="getInfoMeUser.score.position === 3">
                <v-icon>{{ menu.icon }}</v-icon>
              </v-badge>
              <v-badge color="#FFFFFF" :content="getInfoMeUser.score.position + 'ème'" :floating="true" v-if="getInfoMeUser.score.position >= 4">
                <v-icon>{{ menu.icon }}</v-icon>
              </v-badge>
            </template>
            <v-icon v-else>{{ menu.icon }}</v-icon>
          </div>
          <v-icon v-else>{{ menu.icon }}</v-icon>
          <span>{{ menu.text }}</span>
        </v-btn>
      </v-bottom-navigation>
    </v-layout>
  </section>
</template>
<script>
import {userStore} from "@/store/userStore";
import {matcheStore} from "@/store/matcheStore";

export default {
  name: 'BottomNavigationComponent',
  computed: {
    getInfoMeUser() {
      return userStore().getUserInfos
    },
    getNbMatcheScheduledNotBet() {
      return matcheStore().getNbMatcheScheduledNotBet;
    },
    getMenuRole() {
      let menus = []
      if(userStore().getUserRoles.includes('ROLE_VERIFIED')) {
        menus.unshift(
            {
              link: '/matche',
              icon: 'mdi-soccer',
              text: 'Matchs'
            },
            {
              link: '/leadboard',
              icon: 'mdi-podium',
              text: 'Classements'
            },
            {
              link: '/profile',
              icon: 'mdi-account',
              text: 'Profil'
            },
            {
              link: '/menu',
              icon: 'mdi-menu',
              text: 'Menu'
            },
        );
        return menus
      } else {
        return menus
      }
    }
  }
}
</script>
<style scoped>
.height-bottom-navigation {
  height: 80px !important;
  z-index: 999999999;
}
</style>