import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import './registerServiceWorker'
import CountryFlag from 'vue-country-flag-next'

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
loadFonts();

createApp(App).use(router).use(pinia).use(CountryFlag)
  .use(vuetify)
  .mount('#app');
