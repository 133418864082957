import {defineStore} from 'pinia'
import axios from "axios";
import {snackbarStore} from "@/store/snackbarStore";
import {userStore} from "@/store/userStore";

export const tchatStore = defineStore('tchatStore', {
    state: () => {
        return {
            tchatData: null,
        }
    },
    getters: {
        getTchatData: (state) => {
            return state.tchatData;
        },
    },
    actions: {
        async getTchat() {
            await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_TCHAT, {
                headers: {
                    Authorization: 'Bearer ' + userStore().getToken
                }
            }).then(async response => {
                this.tchatData = response.data;
                return true;
            }).catch(error => {
                    snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                    return false;
                }
            );
        },
        async putTchat(text) {
            try {
                const response = await axios.put(
                    process.env.VUE_APP_API_URL + process.env.VUE_APP_API_TCHAT,
                    {
                        text: text,
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + userStore().getToken
                        }
                    }
                );
                snackbarStore().showNotification(response.data.message, true, "green-darken-1", 3000);
                return true;
            } catch (error) {
                snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                return false;
            }
        }
    }
})