<template>
  <section id="breadcrumbs" class="breadcrumbs position-fixed w-100 bg-blue-darken-4">
    <v-icon class="position-icon" v-if="activeReturn && !linkPrevious" @click="goToLink()" color="white" icon="mdi-arrow-left">
    </v-icon>
    <v-icon class="position-icon" v-if="linkPrevious" @click="goPrevious()" color="white" icon="mdi-arrow-left">
    </v-icon>
    <div class="py-6">
      <p class="text-center white--text ma-0 font-weight-black">{{ textBreadcrumbs }}</p>
    </div>
  </section>
</template>
<script>
import router from "@/router";

export default {
  name: 'BreadcrumbsComponent',
  props: {
    textBreadcrumbs: String,
    activeReturn: Boolean,
    linkReturn: String,
    linkPrevious: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    goToLink() {
      router.push(this.linkReturn);
    },
    goPrevious() {
      router.go(-1);
    }
  }
}
</script>
<style scoped>
#breadcrumbs {
  z-index: 9999;
}
.position-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(100%, -50%);
}
</style>