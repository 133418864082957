<template>
  <section id="setting" class="bg-white">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/menu" textBreadcrumbs="Réglages"/>
    <v-list lines="one" style="padding-top: 74px;">
      <v-list-item title="Modifier l'apparence du profil" class="text-left" @click="goTo('/menu/setting/image')">
        <template v-slot:prepend>
          <v-icon icon="mdi-star-face" style="color: #143CDB !important; opacity: 1;"></v-icon>
        </template>
        <template v-slot:append>
          <v-icon icon="mdi-arrow-right" style="padding: 20px; color: #143CDB !important; opacity: 1;"></v-icon>
        </template>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item title="Modifier mes informations" class="text-left" @click="goTo('/menu/setting/info')">
        <template v-slot:prepend>
          <v-icon icon="mdi-information" style="color: #143CDB !important; opacity: 1;"></v-icon>
        </template>
        <template v-slot:append>
          <v-icon icon="mdi-arrow-right" style="padding: 20px; color: #143CDB !important; opacity: 1;"></v-icon>
        </template>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item title="Modifier mon mot de passe" class="text-left" @click="goTo('/menu/setting/password')">
        <template v-slot:prepend>
          <v-icon icon="mdi-lock" style="color: #143CDB !important; opacity: 1;"></v-icon>
        </template>
        <template v-slot:append>
          <v-icon icon="mdi-arrow-right" style="padding: 20px; color: #143CDB !important; opacity: 1;"></v-icon>
        </template>
      </v-list-item>
      <v-list-item title="Modifier mes notifications" class="text-left" @click="goTo('/menu/setting/notification')">
        <template v-slot:prepend>
          <v-icon icon="mdi-bell-ring" style="color: #143CDB !important; opacity: 1;"></v-icon>
        </template>
        <template v-slot:append>
          <v-icon icon="mdi-arrow-right" style="padding: 20px; color: #143CDB !important; opacity: 1;"></v-icon>
        </template>
      </v-list-item>
     <!-- <v-list-item title="Modifier mon e-mail" class="text-left">
        <template v-slot:prepend>
          <v-icon icon="mdi-email" style="color: #143CDB !important; opacity: 1;"></v-icon>
        </template>
        <template v-slot:append>
          <v-icon icon="mdi-arrow-right" style="padding: 20px; color: #143CDB !important; opacity: 1;"></v-icon>
        </template>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item title="Modifier mes alertes" class="text-left">
        <template v-slot:prepend>
          <v-icon icon="mdi-bell-ring" style="color: #143CDB !important; opacity: 1;"></v-icon>
        </template>
        <template v-slot:append>
          <v-icon icon="mdi-arrow-right" style="padding: 20px; color: #143CDB !important; opacity: 1;"></v-icon>
        </template>
      </v-list-item>-->
      <v-divider></v-divider>
    </v-list>
    <v-list lines="one" class="mt-5 text-red">
      <v-list-item title="Se déconnecter" class="text-left" @click="sendLogout()">
        <template v-slot:prepend>
          <v-icon icon="mdi-logout" style="color: red !important; opacity: 1;"></v-icon>
        </template>
      </v-list-item>
      <v-list-item title="Supprimer mon compte" class="text-left" @click="deleteAccount()">
        <template v-slot:prepend>
          <v-icon icon="mdi-delete" style="color: red !important; opacity: 1;"></v-icon>
        </template>
      </v-list-item>
      <!--<v-list-item title="Déclarer forfait" class="text-left" @click="console.log('sds')">
        <template v-slot:prepend>
          <v-icon icon="mdi-thumb-down" style="color: red !important; opacity: 1;"></v-icon>
        </template>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item title="Supprimer mon compte" class="text-left" @click="console.log('sds')">
        <template v-slot:prepend>
          <v-icon icon="mdi-delete" style="color: red !important; opacity: 1;"></v-icon>
        </template>
      </v-list-item>-->
    </v-list>
    <BottomNavigationComponent />
  </section>
</template>

<script>
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent";
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import {userStore} from "@/store/userStore";
import router from "@/router";
export default {
  name: 'SettingView',
  components: {BreadcrumbsComponent, BottomNavigationComponent},
  methods: {
    sendLogout() {
      userStore().logout();
    },
    goTo(link) {
      router.push(link);
    },
    deleteAccount() {
      alert('Pour le moment il n\'y a pas de procédure automatique pour supprimer votre compte contacter l\'organisateur pour supprimer votre compte !');
    }
  }
}
</script>
<style scoped>
::v-deep(.v-list-item__prepend) {
  display: inline !important;
}
#setting {
  height: 100vh;
}
</style>
