import {defineStore} from 'pinia'
import axios from "axios";
import {snackbarStore} from "@/store/snackbarStore";
import router from "@/router";
import { jwtDecode } from 'jwt-decode';

export const userStore = defineStore('userStore', {
    state: () => {
        return {
            token: null,
            timeTokenExpire: null,
            connected: false,
            userRoles: [],
            userInfos: [],
            otherUserInfos: [],
        }
    },
    getters: {
        getToken: (state) => {
            return state.token;
        },
        getTimeTokenExpire: (state) => {
            return state.token;
        },
        isConnected: (state) => {
            return state.token !== null && (state.auth !== null || true);
        },
        getUserRoles: (state) => {
            return state.userRoles;
        },
        getUserInfos: (state) => {
            return state.userInfos;
        },
        getOtherUserInfos: (state) => {
            return state.otherUserInfos;
        },
    },
    actions: {
        async register(email, password, firstname, nickname) {
            await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTER, {
                email: email,
                password: password,
                firstname: firstname,
                nickname: nickname
            }).then(async response => {
                await router.push("login");
                snackbarStore().showNotification(response.data.message, true, "green-darken-1", 5000);
                return true;
            }).catch(error => {
                    snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                    return false;
                }
            );
        },
        async login(email, password, remember) {
            await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_LOGIN, {
                email: email,
                password: password,
                remember: remember
            }).then(async response => {
                this.userRoles = jwtDecode(response.data.token).roles
                if(!userStore().getUserRoles.includes('ROLE_VERIFIED')) {
                    snackbarStore().showNotification("Votre compte n'est pas encore vérifié ! Merci de bien vouloir patienter !", true, "red-darken-1", 5000);
                    await router.push("/");
                    return false;
                }
                this.token = response.data.token;
                this.timeTokenExpire = jwtDecode(response.data.token).exp
                this.connected = true;
                await router.push("menu"); //todo CHANGE HERE
                snackbarStore().showNotification("Vous êtes connecté avec succès !", true, "green-darken-1", 5000);
                return true;
            }).catch(error => {
                console.log(error);
                    snackbarStore().showNotification("Impossible de se connecter", true, "red-darken-1", 5000);
                    return false;
                }
            );
        },
        async logout() {
            this.token = null;
            this.timeTokenExpire = null;
            this.connected = false;
            this.userInfos = [];
            this.otherUserInfos = [];
            await router.push("/");
            snackbarStore().showNotification("Déconnexion réussie !", true, "green-darken-1", 5000);
            return true;
        },
        async forgotPassword(email) {
            await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_FORGOT_PASSWORD, {
                email: email,
            }).then(async response => {
                await router.push("login");
                snackbarStore().showNotification(response.data.message, true, "green-darken-1", 5000);
                return true;
            }).catch(error => {
                    snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                    return false;
                }
            );
        },
        async resetPassword(token, password) {
            await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_RESET_PASSWORD, {
                token: token,
                password: password
            }).then(async response => {
                await router.push("login");
                snackbarStore().showNotification(response.data.message, true, "green-darken-1", 5000);
                return true;
            }).catch(error => {
                    snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                    return false;
                }
            );
        },
        async userInfo(id) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_USER_INFO + id, {
                    headers: {
                        Authorization: 'Bearer ' + userStore().getToken
                    }
                });
                if(id === "me") {
                    this.userInfos = response.data;
                } else {
                    this.otherUserInfos = response.data;
                }
                return true;
            } catch (error) {
                snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                return false;
            }
        },
        async userUploadFile(type, file) {
            const formData = new FormData();
            formData.append('type', type);
            formData.append('file', file);
            try {
                const response = await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_EDIT_PROFIL, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: 'Bearer ' + userStore().getToken
                    }
                });
                snackbarStore().showNotification(response.data.message, true, "green-darken-1", 5000);
                await router.push("/menu");
                return true;
            } catch (error) {
                snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                return false;
            }
        },
        async editInfo(firstname, nickname) {
            await axios.put(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_EDIT_INFO, {
                firstname: firstname,
                nickname: nickname
            },{
                headers: {
                    Authorization: 'Bearer ' + userStore().getToken }
            }).then(async response => {
                await router.push("/menu/setting");
                snackbarStore().showNotification(response.data.message, true, "green-darken-1", 5000);
                return true;
            }).catch(error => {
                    snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                    return false;
                }
            );
        },
        async editPassword(actualPassword, newPassword) {
            await axios.put(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_EDIT_PASSWORD, {
                actualPassword: actualPassword,
                newPassword: newPassword
            },{
                headers: {
                    Authorization: 'Bearer ' + userStore().getToken }
            }).then(async response => {
                await router.push("/menu/setting");
                snackbarStore().showNotification(response.data.message, true, "green-darken-1", 5000);
                return true;
            }).catch(error => {
                    snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                    return false;
                }
            );
        },
    },
    persist: {
        storage: localStorage,
        paths: ['token', 'timeTokenExpire' ,'connected', 'userRoles'],
    },
})