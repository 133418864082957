import {defineStore} from 'pinia'
import axios from "axios";
import {snackbarStore} from "@/store/snackbarStore";
import {userStore} from "@/store/userStore";

export const leadboardStore = defineStore('leadboardStore', {
    state: () => {
        return {
            dataLeadboard: null,
            showDialogUserInfos: false
        }
    },
    getters: {
        getDataLeadboard: (state) => {
            return state.dataLeadboard;
        },
        getShowDialogUserInfos: (state) => {
            return state.showDialogUserInfos;
        },
    },
    actions: {
        async callGetLeadboard() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_LEADBOARD,{
                    headers: { Authorization: 'Bearer ' + userStore().getToken }
                });
                this.dataLeadboard = response.data;
                return true;
            } catch(error) {
                snackbarStore().showNotification(error, true, "red-darken-1", 5000);
                return false;
            }
        },
    }
})