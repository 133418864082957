<template>
  <section id="edit-info" class="bg-white">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/menu/setting" textBreadcrumbs="Modifier Informations"/>
    <v-container style="padding-top: 90px;">
    <v-text-field
        variant="solo"
        v-model="firstname" :error-messages="v$.firstname.$errors.map(e => e.$message)" @input="v$.firstname.$touch" @blur="v$.firstname.$touch"
        type="text"
    >
      <template v-slot:label>
        Prénom<span class="text-error ml-1"> *</span>
      </template>
    </v-text-field>
    <v-text-field
        variant="solo"
        v-model="nickname" :error-messages="v$.nickname.$errors.map(e => e.$message)" @input="v$.nickname.$touch" @blur="v$.nickname.$touch"
        type="text"
    >            <template v-slot:label>
      Pseudo<span class="text-error ml-1"> *</span>
    </template></v-text-field>
        <v-btn size="large" :loading="loadingEditInfoBtn" class="mt-5 ma-auto d-flex font-weight-bold" color="green-darken-1" @click="sendEditInfo()">Enregistrer</v-btn>
    </v-container>
    <BottomNavigationComponent />
  </section>
</template>

<script>
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent";
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import {useVuelidate} from "@vuelidate/core/dist/index.mjs";
import {helpers, required} from "@vuelidate/validators";
import {userStore} from "@/store/userStore";

export default {
  name: 'EditInfoView',
  components: {BreadcrumbsComponent, BottomNavigationComponent},
  data() {
    return {
      firstname: null,
      nickname: null,
      loadingEditInfoBtn: false
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      firstname: {
        required: helpers.withMessage("Le prénom est requis.", required),
      },
      nickname: {
        required: helpers.withMessage("L'pseudo est requis.", required),
      },
    }
  },
  methods: {
    async sendEditInfo() {
      this.loadingEditInfoBtn = true;
      const result = await this.v$.$validate()
      if (!result) {
        this.loadingEditInfoBtn = false;
        return false;
      }
      await userStore().editInfo(this.firstname, this.nickname);
      this.loadingEditInfoBtn = false;
    }
  }
}
</script>
<style scoped>
#edit-info {
  height: 100vh;
}
</style>
