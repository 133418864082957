<template>
  <section id="rules" class="bg-white">
  <BreadcrumbsComponent :activeReturn="true" linkReturn="/menu" textBreadcrumbs="Règlements"/>
  <v-carousel class="pt-16 bg-blue-darken-4" :show-arrows="false" hide-delimiters style="height: 40vh">
    <v-carousel-item contain :src="require('@/assets/images/background-connection-page.png')"></v-carousel-item>
  </v-carousel>
    <v-container style="padding: 0 16px 100px 16px; background: white">
    <h2 class="my-3 text-center">Pariosa Règlements</h2>
    <v-card class="pa-4">
      <p class="text-justify mb-2">Pariosa est un jeu fun et rigolo de pronostics gratuit à faire entre amis, famille ou bien
        collègues !</p>
      <p class="text-justify mb-2">Votre but ? Essayer de prédire au mieux le résultat des différents matchs lors de l'Euro
        2024 !</p>
      <p class="text-justify mb-2">Tu prédis le plus juste le score, alors tu gagneras plus de points.</p>
      <p class="text-center mb-2">Voici comment sont calculés les points :</p>
      <v-expansion-panels variant="accordion">
        <v-expansion-panel
            expand-icon="mdi-plus-circle"
            collapse-icon="mdi-close-circle"
        >
          <v-expansion-panel-title>
            <v-badge floating dot color="blue">
              <b class="mr-2">+10</b> Score exact
            </v-badge>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            (Prono: 2-1, Score réel: 2-1).<br>Point non cumulable avec les points VERT.
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel
            expand-icon="mdi-plus-circle"
            collapse-icon="mdi-close-circle"
        >
          <v-expansion-panel-title>
            <v-badge floating dot color="green">
              <b class="mr-2">+5</b> Score correct pour victoire, match nul ou une défaite
            </v-badge>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            (Prono: 1-3, Score réel: 0-2).<br>Point non cumulable avec les points BLEU.
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel
            expand-icon="mdi-plus-circle"
            collapse-icon="mdi-close-circle"
        >
          <v-expansion-panel-title>
            <v-badge floating dot color="green">
              <b class="mr-2">+1</b> Score nombre exact de buts pour une des équipes
            </v-badge>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            (Prono: 2-4, Score réel: 2-0).<br>Point non cumulable avec les points BLEU.
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <p class="text-justify my-2">Avant le début de la compétition, tu peux réaliser tous les pronos des matchs de poule ou bien au fur et à
        mesure.</p>
      <p class="text-justify mb-2">A savoir que tu peux changer autant de fois que tu veux ton pronostic jusqu'à la dernière seconde du coup d'envoi
        d'un match.</p>
      <p class="text-justify mb-2">A partir des <b>1/8èmes de finale</b>, on prend en compte le score à l'issue des prolongations.</p>
      <p class="text-justify mb-2">De même, les éventuels <b>tirs au but</b> ne sont pas pris en compte dans les pronos.</p>
      <h3 class="text-center mt-2">Bonne chance !</h3>
      <h3 class="text-center mb-2">Que la force soit avec toi !</h3>
      <div class="d-flex justify-center">
      <v-btn class="mb-2 font-weight-bold" @click="returnBetPage()" density="default" color="blue-darken-4" size="large">Retourner parier les matchs</v-btn>
      </div>
    </v-card>
  </v-container>
  <BottomNavigationComponent />
  </section>
</template>

<script>
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import router from "@/router";
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent.vue";

export default {
  name: 'RulesView',
  components: {BottomNavigationComponent, BreadcrumbsComponent},
  methods: {
    returnBetPage() {
      router.push("/matche");
    }
  }
}
</script>
<style scoped>
#rules {
  height: 100vh;
}
</style>