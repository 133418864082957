<template>
  <section id="tchat" class="bg-white">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/matche" textBreadcrumbs="Tchat"/>
    <v-container class="pt-0">
      <v-row dense no-gutters class="pb-15 pt-15" style="margin-bottom: 150px; margin-top: 25px">
        <v-col v-for="message in getTchatData" :key="message.id" cols="12">
          <v-card class="mb-3 message w-100">
            <v-row no-gutters align="center" :id="message.id">
              <v-col cols="2" class="justify-center align-content-center d-flex">
                <v-avatar class="mr-1" v-if="message.account.avatarUrl != null">
                  <v-img
                      v-if="message.account.avatarUrl != null"
                      :src="linkApi + 'users/avatar/' + message.account.avatarUrl"
                  >
                    <template v-slot:placeholder>
                      <v-progress-circular indeterminate></v-progress-circular>
                    </template>
                  </v-img>
                </v-avatar>
                <v-avatar class="mr-1" color="grey-lighten-1" v-else>
                  {{ message.lastname.slice(0, 2).toUpperCase() }}
                </v-avatar>
              </v-col>
              <v-col cols="10">
                <v-card-title class="text-subtitle-1 pa-0">{{ message.account.lastname }}</v-card-title>
                <v-card-text class="pa-0 mb-1">{{ message.text }}</v-card-text>
                <v-card-subtitle class="pa-0 text-caption text-capitalize">{{ formatDate(message.createdAt) }}</v-card-subtitle>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense no-gutters id="scrollAnchor">
      </v-row>
    </v-container>
    <div class="input-container">
      <v-text-field hide-details v-model="newMessage" placeholder="Écrire un message" class="message-input" outlined></v-text-field>
      <v-btn icon="mdi-send" @click="sendMessage" class="send-button" :loading="loadingBtn"></v-btn>
    </div>
    <BottomNavigationComponent />
  </section>
</template>

<script>
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent";
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import { tchatStore } from "@/store/tchatStore";

export default {
  name: 'TchatView',
  components: { BreadcrumbsComponent, BottomNavigationComponent },
  data() {
    return {
      linkApi: process.env.VUE_APP_API_URL_PUBLIC,
      loadingBtn: false,
      newMessage: null
    };
  },
  computed: {
    getTchatData() {
      return tchatStore().getTchatData;
    }
  },
  async mounted() {
    await this.getTchat();
    const textElement = document.getElementById("scrollAnchor");
    textElement.scrollIntoView({behavior: "smooth"})
    setInterval(() => {
      this.getTchat();
    }, 10000);
  },
  methods: {
    async getTchat() {
      await tchatStore().getTchat();
    },
    formatDate(isoDate) {
      const date = new Date(isoDate);
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      };
      return date.toLocaleDateString('fr-FR', options);
    },
    async sendMessage() {
      this.loadingBtn = true;
      if (this.newMessage.trim() !== '') {
        await tchatStore().putTchat(this.newMessage);
        this.newMessage = null;
      }
      await tchatStore().getTchat();
      this.loadingBtn = false;
      const textElement = document.getElementById("scrollAnchor");
      textElement.scrollIntoView({behavior: "smooth"})
    }
  }
};
</script>

<style scoped>
#tchat {
  height: 100vh !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  position: fixed;
  bottom: 80px;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.message-input {
  flex: 1;
}

.send-button {
  margin-left: 10px;
}

.message {
  align-items: start;
  background-color: #e0f7fa;
}
</style>