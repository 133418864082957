<template>
  <section id="userInfosComponent">
    <v-dialog
        style="z-index: 9999"
        v-model="getDialogFixtureInfos"
        fullscreen
        transition="dialog-bottom-transition"
    >
      <v-card style="padding-bottom: 150px">
        <v-card-text class="pa-0">
      <section class="position-fixed w-100 bg-blue-darken-4">
        <v-icon class="position-icon" color="white" icon="mdi-close" @click="closeDialogFixtureInfos()">
        </v-icon>
        <div class="py-3">
          <p class="text-center white--text ma-0 font-weight-black"> Infos du Match</p>
        </div>
      </section>
      <section>
        <v-row :no-gutters="true" class="pt-13">
          <v-col cols="3" class="justify-center align-center d-flex flex-column">
            <div style="font-size:65px">
              <country-flag :rounded="true" :country="getFixtureInfos.team1.isoFlag" size='big' style="box-shadow: rgba(227,227,227,0.99) 0px 0px 3px, rgba(227,227,227,0.99) 0px 0px 0px 3px;"/>
            </div>
            <p>{{ getFixtureInfos.team1.name }}</p>
          </v-col>
          <v-col cols="6">
            <v-row :no-gutters="true">
              <v-col cols="12" class="pb-2 text-center text-caption">
                {{getFixtureInfos.type}}
              </v-col>
              <v-col cols="5" class="text-left font-weight-bold text-blue-darken-4" style="font-size: 32px;">
                <input
                    disabled
                    :value="getFixtureInfos.goalTeam1"
                    class="text-center"
                    style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid #143cdb; color: #143cdb;"
                />
              </v-col>
              <v-col col="2" class="d-flex justify-center align-center">
                <p>-</p>
              </v-col>
              <v-col cols="5" class="text-right font-weight-bold" style="font-size: 32px;">
                <input
                    disabled
                    :value="getFixtureInfos.goalTeam2"
                    class="text-center"
                    style="display: block; width: 50px; margin-right: auto;margin-left: auto; border: 1px solid #143cdb; color: #143cdb;"
                />
              </v-col>
              <v-col cols="12" class="text-center" v-if="getFixtureInfos.pool !== null">
                <p class="mt-3" style="font-size: 12px">Groupe {{ getFixtureInfos.pool }}</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" class="justify-center align-center d-flex flex-column">
            <div style="font-size:65px;">
              <country-flag :rounded="true" :country="getFixtureInfos.team2.isoFlag" size='big' style="box-shadow: rgba(227,227,227,0.99) 0px 0px 3px, rgba(227,227,227,0.99) 0px 0px 0px 3px;"/>
            </div>
            <p>{{ getFixtureInfos.team2.name }}</p>
          </v-col>
        </v-row>
            <v-row :no-gutters="true" class="py-5">
              <v-col cols="12" class="text-center d-flex justify-center align-center align-content-center flex-row">
                <p>Paris des Joueurs</p>
              </v-col>
            </v-row>
        <v-card class="mb-2 mx-2" style="z-index: -1" v-for="userBets in getFixtureInfos.usersBets" v-bind:key="userBets.id">
            <v-row :no-gutters="true"  >
              <v-col cols="3" class="text-center d-flex justify-center align-center align-content-center flex-column">
                <v-row :no-gutters="true">
                  <v-col col="12" class="text-center d-flex justify-center align-center align-content-center flex-column">
                    <p style="font-size: 12px">{{ userBets.firstname }}</p>
                    <p style="font-size: 8px">[#{{ userBets.nickname }}]</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="text-center d-flex justify-center align-center align-content-center flex-column">
                <v-row :no-gutters="true">
                  <v-col col="5" class="text-center d-flex justify-center align-center align-content-center flex-row">
                    <country-flag style="z-index: -1" class="ma-0 pa-0 text-center d-flex justify-center align-center align-content-center flex-row" :rounded="true" :country="getFixtureInfos.team1.isoFlag" size='medium' /><p>
                    {{ userBets.goalUserTeam1 }}</p>
                  </v-col>
                  <v-col cols="2" class="text-center d-flex justify-center align-center align-content-center flex-row">
                    <span class="mx-3">-</span>
                  </v-col>
                  <v-col cols="5" class="text-center d-flex justify-center align-center align-content-center flex-row">
                    <p>{{ userBets.goalUserTeam2 }}</p><country-flag style="z-index: -1" class="ma-0 pa-0 text-center d-flex justify-center align-center align-content-center flex-row" :rounded="true" :country="getFixtureInfos.team2.isoFlag" size='medium' />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3" class="text-center d-flex justify-center align-center align-content-center flex-column">
                <v-row :no-gutters="true">
                  <v-col col="12" class="text-center d-flex justify-center align-center align-content-center flex-row">
                    <p style="font-size: 12px"><b>{{ userBets.point }}</b> Points</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
        </v-card>
      </section>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import {fixtureStore} from "@/store/fixtureStore";

export default {
  name: 'MatcheInfosComponent',
  computed: {
    getFixtureInfos() {
      return fixtureStore().getFixtureInfos;
    },
    getDialogFixtureInfos() {
      return fixtureStore().getShowDialogFixtureInfos;
    },
  },
  methods: {
    closeDialogFixtureInfos() {
      fixtureStore().showFixtureInfos = false;
    }
  }
}
</script>
<style scoped>
#userInfosComponent {
  height: 100vh;
}
.position-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-100%, -50%);
}
</style>