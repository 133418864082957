import {defineStore} from 'pinia'
import axios from "axios";
import {snackbarStore} from "@/store/snackbarStore";
import {userStore} from "@/store/userStore";
export const adminStore = defineStore('adminStore', {
    state: () => {
        return {
            dataFixture: null,
        }
    },
    getters: {
        getDataFixture: (state) => {
            return state.dataFixture;
        },
    },
    actions: {
        async callGetAllFixture() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'admin/fixture',{
                    headers: { Authorization: 'Bearer ' + userStore().getToken }
                });
                this.dataFixture = response.data;
                return true;
            } catch(error) {
                snackbarStore().showNotification(error, true, "red-darken-1", 5000);
                return false;
            }
        },
        async putDataFixture(idFixture, goalTeam1, goalTeam2, finish) {
            await axios.put(process.env.VUE_APP_API_URL + 'admin/fixture', {
                fixtureId: idFixture,
                goalTeam1: goalTeam1,
                goalTeam2: goalTeam2,
                finish: finish },{
                headers: {
                    Authorization: 'Bearer ' + userStore().getToken }
            }).then(async response => {
                console.log(response);
                snackbarStore().showNotification("Good job", true, "green-darken-1", 5000);
                return true;
            }).catch(error => {
                    snackbarStore().showNotification(error.response.data.message, true, "red-darken-1", 5000);
                    return false;
                }
            );
        },
    }
})