import { defineStore } from 'pinia'
export const snackbarStore = defineStore('snackbarStore', {
    state: () => {
        return {
            snackbarNotifData: {
                show: false,
                message: '',
                color: 'green-darken-1',
                timeout: 5000
            }
        }
    },
    actions: {
        showNotification(message, show, color, timeout) {
            this.snackbarNotifData = {show, message, color, timeout};
        }
    }
})