<template>
  <section id="faq" class="bg-white">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/menu" textBreadcrumbs="Foire aux Questions"/>
    <v-container style="padding: 100px 16px; background: white">
      <v-expansion-panels :multiple="true" variant="accordion">
        <!-- Question 1 -->
        <v-expansion-panel>
          <v-expansion-panel-title>Je ne reçois pas les emails ?</v-expansion-panel-title>
          <v-expansion-panel-text>
            <p>Il est possible que les emails soient considérés comme du spam. Assurez-vous d'expliquer à votre boîte mail de ne pas considérer notre email comme du spam.</p>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Question 2 -->
        <v-expansion-panel>
          <v-expansion-panel-title>Je ne reçois pas les emails ? Même dans les spams</v-expansion-panel-title>
          <v-expansion-panel-text>
            <p>Vérifiez vos notifications email et assurez-vous qu'elles sont bien activées sur votre compte. Si le problème persiste, contactez l'organisateur.</p>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Question 3 -->
        <v-expansion-panel>
          <v-expansion-panel-title>Je peux faire mes pronos jusqu'à quand ?</v-expansion-panel-title>
          <v-expansion-panel-text>
            <p>Vous pouvez faire vos pronostics jusqu'à la dernière seconde avant le coup d'envoi d'un match.</p>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Question 4 -->
        <v-expansion-panel>
          <v-expansion-panel-title>Je peux commencer en cours de compétition ?</v-expansion-panel-title>
          <v-expansion-panel-text>
            <p>Bien sûr ! Cependant, vous ne pourrez pas parier sur les matchs terminés, cela va de soi.</p>
            <p>Nous croyons en vous pour une jolie remontée dans le classement.</p>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Question 5 -->
        <v-expansion-panel>
          <v-expansion-panel-title>C'est payant ?</v-expansion-panel-title>
          <v-expansion-panel-text>
            <p class="text-justify">Non c'est gratuit pour l'euro 2024. Cette application est nouvelle avec une meilleur stabilité que l'ancienne mais des bugs peuvent être présent !</p>
            <p class="text-justify">Je suis seul à développer l'application !</p>
            <p class="text-justify">Cependant vous pouvez envoyer un don de 2 euros. Le serveur et la base de données ne sont malheureusement pas gratuits.</p>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Question 6 -->
        <v-expansion-panel>
          <v-expansion-panel-title>Je viens de m'inscrire mais je ne peux pas parier pourquoi ?</v-expansion-panel-title>
          <v-expansion-panel-text>
            <p class="text-justify">Pas de panique ! Une vérification de votre compte est nécessaire (maximum 48 heures). Une fois votre compte validé, vous recevrez un email.</p>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Question 7 -->
        <v-expansion-panel>
          <v-expansion-panel-title>Je me suis inscrit depuis plus de 48 heures, pourquoi je ne peux toujours pas parier ?</v-expansion-panel-title>
          <v-expansion-panel-text>
            <p>Si les 48 heures sont dépassées, contactez l'organisateur. Assurez-vous également de vérifier si vous n'avez pas reçu un email de refus.</p>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Question 8 -->
        <v-expansion-panel>
          <v-expansion-panel-title>J'ai perdu mon mot de passe, comment faire ?</v-expansion-panel-title>
          <v-expansion-panel-text>
            <p>Si vous avez perdu votre mot de passe, vous pouvez utiliser la fonctionnalité "Mot de passe oublié" pour réinitialiser votre mot de passe. Sinon, contactez l'organisateur pour obtenir de l'aide.</p>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Question 9 -->
        <v-expansion-panel>
          <v-expansion-panel-title>J'ai un souci qui n'est pas dans la FAQ !</v-expansion-panel-title>
          <v-expansion-panel-text>
            <p>Si vous rencontrez un problème qui n'est pas répertorié dans la FAQ, veuillez contacter l'organisateur en expliquant clairement le problème rencontré.</p>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Question 10 -->
        <v-expansion-panel>
          <v-expansion-panel-title>J'ai trouvé un bug !</v-expansion-panel-title>
          <v-expansion-panel-text>
            <p>Si vous avez trouvé un bug, veuillez contacter l'organisateur en expliquant clairement le bug rencontré (visuel, fonctionnalité affectée, heure, etc.).</p>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
    <BottomNavigationComponent />
  </section>
</template>

<script>
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import BottomNavigationComponent from "@/components/General/BottomNavigationComponent.vue";

export default {
  name: 'FaqView',
  components: {BottomNavigationComponent, BreadcrumbsComponent},
  data() {
    return {}
  },
}
</script>
<style scoped>
#faq {
  height: 100vh;
}
</style>