<template>
  <section id="login" class="bg-blue-darken-4">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/" textBreadcrumbs="Se connecter"/>
    <div style="padding-top: 80px">
    <v-img
        class="ma-auto"
        :width="300"
        aspect-ratio="1/1"
        :cover="true"
        :src="require('@/assets/images/background-connection-page.png')"
    ></v-img>
    </div>
    <h4 class="text-center">Connecte toi pour parier comme un pro !</h4>
    <v-sheet class="bg-blue-darken-4 mx-auto d-flex align-center justify-center flex-column">
      <v-form class="w-75 mt-10">
        <div class="bg-white px-5 pt-8 pb-5 mb-5 rounded">
          <v-text-field
              variant="solo"
              v-model="email" :error-messages="v$.email.$errors.map(e => e.$message)" @input="v$.email.$touch" @blur="v$.email.$touch"
              type="email"
          >
            <template v-slot:label>
              Email<span class="text-error ml-1"> *</span>
            </template>
          </v-text-field>
          <v-text-field
              variant="solo"
              v-model="password" :error-messages="v$.password.$errors.map(e => e.$message)" @input="v$.password.$touch" @blur="v$.password.$touch"
              :type="typeFieldPassword"
              autocomplete="on"
              @click:appendInner="showPassword()"
              :append-inner-icon="showPasswordIcon ? 'mdi-eye-off' : 'mdi-eye'"
          >            <template v-slot:label>
            Mot de passe<span class="text-error ml-1"> *</span>
          </template></v-text-field>
          <v-checkbox class="text-center d-flex justify-center" v-model="remember" label="Restez connecté" required></v-checkbox>
        </div>
        <v-btn :loading="loadingLoginBtn" size="x-large" color="white" class="font-weight-bold d-flex ma-auto justify-center align-content-center align-center" @click="sendLogin();">Se connecter</v-btn>
        <p class="text-center text-decoration-underline mt-5 mb-5 cursor-text"><a @click="goTo('register')" class="text-white">Je n'ai pas de compte</a></p>
        <p class="text-center text-decoration-underline mt-2 mb-10 cursor-text"><a @click="goTo('forgot-password')" class="text-white">J'ai oublié mon mot de passe</a></p>
      </v-form>
    </v-sheet>
  </section>
</template>

<script>
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import router from "@/router";
import {useVuelidate} from '@vuelidate/core'
import {email, helpers, required} from '@vuelidate/validators'
import {userStore} from "@/store/userStore";

export default {
  name: 'LoginView',
  components: {BreadcrumbsComponent},
  data() {
    return {
      email: null,
      password: null,
      typeFieldPassword: "password",
      remember: true,
      showPasswordIcon: false,
      loadingLoginBtn: false,
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage("L'e-mail est requis.", required),
        email: helpers.withMessage("L'e-mail n'est pas une adresse e-mail valide.", email)
      },
      password: {
        required: helpers.withMessage('Le mot de passe est requis.', required),
        minLength: helpers.withMessage('Le mot de passe est trop court.', (value) => value.length >= 8),
        maxLength: helpers.withMessage('Le mot de passe est trop long.', (value) => value.length <= 255)
      },
    }
  },
  methods: {
    goTo(link) {
      router.push(link);
    },
    showPassword() {
      this.showPasswordIcon = !this.showPasswordIcon;
      this.typeFieldPassword === "text" ? this.typeFieldPassword = "password" : this.typeFieldPassword = "text";
    },
    async sendLogin() {
      this.loadingLoginBtn = true;
      const result = await this.v$.$validate()
      if (!result) {
        this.loadingLoginBtn = false;
        return false
      }
      await userStore().login(this.email, this.password, this.remember);
      this.loadingLoginBtn = false;
    }
  }
}
</script>
<style scoped>
#login {
  height: 100vh;
}
:deep(.v-messages) {
  text-align: left !important;
}
:deep(.v-label) {
  opacity: 1 !important;
}
.cursor-text {
  cursor: pointer;
}
</style>
