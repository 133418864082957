<template>
  <section id="register" class="bg-blue-darken-4">
    <BreadcrumbsComponent :activeReturn="true" linkReturn="/" textBreadcrumbs="S'inscrire"/>
    <div style="padding-top: 80px">
    <v-img
        class="ma-auto pt-16"
        :width="300"
        aspect-ratio="1/1"
        :cover="true"
        :src="require('@/assets/images/background-connection-page.png')"
    ></v-img>
    </div>
    <h4 class="text-center">Rejoignez Pariosa c'est juste en dessous !</h4>
    <v-sheet class="mx-auto mt-2 d-flex align-content-center align-center justify-center flex-column bg-blue-darken-4">
      <v-form class="w-75 mt-10">
        <div class="bg-white px-5 pt-8 pb-5 mb-5 rounded">
          <v-text-field
              variant="solo"
              v-model="firstname" :error-messages="v$.firstname.$errors.map(e => e.$message)" @input="v$.firstname.$touch" @blur="v$.firstname.$touch"
              type="text"
          ><template v-slot:label>
            Prénom<span class="text-error ml-1"> *</span>
          </template></v-text-field>
          <v-text-field
              variant="solo"
              v-model="nickname" :error-messages="v$.nickname.$errors.map(e => e.$message)" @input="v$.nickname.$touch" @blur="v$.nickname.$touch"
              type="text"
          >
            <template v-slot:label>
              Pseudo<span class="text-error ml-1"> *</span>
            </template>
          </v-text-field>
          <v-text-field
              variant="solo"
              v-model="email" :error-messages="v$.email.$errors.map(e => e.$message)" @input="v$.email.$touch" @blur="v$.email.$touch"
              type="email"
          >
            <template v-slot:label>
              Email<span class="text-error ml-1"> *</span>
            </template>
          </v-text-field>
          <v-text-field
              variant="solo"
              v-model="emailConfirm" :error-messages="v$.emailConfirm.$errors.map(e => e.$message)" @input="v$.emailConfirm.$touch" @blur="v$.emailConfirm.$touch"
              type="email"
          >
            <template v-slot:label>
              Confirmer email<span class="text-error ml-1"> *</span>
            </template>
          </v-text-field>
          <v-text-field
              variant="solo"
              v-model="password" :error-messages="v$.password.$errors.map(e => e.$message)" @input="v$.password.$touch" @blur="v$.password.$touch"
              :type="typeFieldPassword"
              autocomplete="on"
              @click:appendInner="showPassword()"
              :append-inner-icon="showPasswordIcon ? 'mdi-eye-off' : 'mdi-eye'"
          >            <template v-slot:label>
            Mot de passe<span class="text-error ml-1"> *</span>
          </template></v-text-field>
          <v-text-field
              variant="solo"
              v-model="passwordConfirm" :error-messages="v$.passwordConfirm.$errors.map(e => e.$message)" @input="v$.passwordConfirm.$touch" @blur="v$.passwordConfirm.$touch"
              :type="typeFieldPasswordConfirm"
              autocomplete="on"
              @click:appendInner="showPasswordConfirm()"
              :append-inner-icon="showPasswordConfirmIcon ? 'mdi-eye-off' : 'mdi-eye'"
          >
            <template v-slot:label>
              Confirmer mot de passe<span class="text-error ml-1"> *</span>
            </template>
          </v-text-field>
          <v-checkbox class="text-left d-flex justify-center flex-column align-center" v-model="confirmCGU" :error-messages="v$.confirmCGU.$errors.map(e => e.$message)" @change="v$.confirmCGU.$touch" @blur="v$.confirmCGU.$touch">
            <template v-slot:label>
              En cochant cette case, vous acceptez les conditions d'utilisation du site.<span class="text-error ml-1"> *</span>
            </template>
          </v-checkbox>
          <v-row :no-gutters="true">
            <v-col cols="12" class="d-flex justify-center align-content-center align-center flex-column">
              <v-btn color="blue-darken-4" size="large" class="text-center font-weight-bold pa-2" @click="dialogConditionUtilisation = true">Conditions d'utilisation</v-btn>
              <p>{{ audioPlaying ? "Arrêter les conditions d'utilisation" : "Écouter les conditions d'utilisation" }}</p>
              <p>Signé ChatGPT</p>
              <v-btn color="blue-darken-4" :icon="audioPlaying ? 'mdi-pause' : 'mdi-play'" @click="toggleAudio">
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-btn :loading="loadingRegisterBtn" size="x-large" class="ma-auto justify-center align-center d-flex bg-white font-weight-bold" @click="sendRegister()">S'inscrire</v-btn>
        <p class="text-decoration-underline mt-5 mb-10 ma-auto justify-center align-center d-flex"><a @click="goTo('login')" class="text-white cursor-text">J'ai déja un compte</a></p>
      </v-form>
    </v-sheet>
  </section>
  <v-dialog v-model="dialogConditionUtilisation" max-width="600">
    <v-card>
      <v-card-title class="text-center">
        <span class="headline">Conditions d'utilisation</span>
      </v-card-title>
      <v-card-text class="py-0">
        <div>
          <p class="text-justify"><strong>Bienvenue dans les conditions d'utilisation les plus flemmardes jamais écrites !</strong></p>
          <p>On pourrait résumer ça en disant : "Je stocke quelques infos basiques et j'ai vraiment la flemme de coder un truc compliqué pour espionner votre vie, sérieusement, qui a le temps pour ça ?" Mais voici une version un peu plus détaillée, juste pour le fun :</p>
          <ul class="ml-5">
            <li><strong>Stockage des données :</strong> Je stocke votre email et votre mot de passe. Pourquoi ? Parce que, eh bien, je suppose que c'est ce que font les autres sites, non ? Et aussi parce que j'ai la flemme de penser à un autre système de connexion.</li>
            <li><strong>Vos pronos et autres trucs sympas :</strong> Oui, je les stocke aussi. C'est juste pour me rappeler que vous avez prédit que la Corée du Nord gagnera la coupe du monde d'ici 2026. Ça pourrait être utile un jour, qui sait ?</li>
            <li><strong>Espionnage :</strong> Ah, non merci. J'ai mieux à faire que de fouiller dans votre vie privée. Franchement, ça me paraît fatigant.</li>
            <li><strong>Engagement :</strong> Vous acceptez que je sois un peu paresseux et que je ne vais pas vous harceler avec des tonnes de mises à jour ou des trucs du genre. On est cool comme ça, okay ?</li>
            <li><strong>Confidentialité :</strong> Vos données sont en sécurité. Enfin, tant que personne ne trouve mon vieux ordinateur dans le garage. Mais sérieusement, je ferai de mon mieux pour les protéger.</li>
            <li><strong>Modification des conditions :</strong> Ouais, peut-être un jour, si j'ai vraiment, vraiment rien d'autre à faire. Mais honnêtement, ça m'étonnerait.</li>
            <li><strong>Contact :</strong> Vous pouvez essayer de me contacter si vous avez des questions, mais ne soyez pas surpris si ma réponse est juste un emoji de personne dormant.</li>
          </ul>
          <p>Voilà, vous êtes prêt à utiliser ce service totalement flemmard !</p>
          <p>Signé <strong>ChatGPT</strong></p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialogConditionUtilisation = false">Ok j'ai compris</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {useVuelidate} from "@vuelidate/core/dist/index.mjs";
import {email, helpers, required, sameAs} from "@vuelidate/validators";
import BreadcrumbsComponent from "@/components/General/BreadcrumbsComponent";
import router from "@/router";
import {userStore} from "@/store/userStore";

export default {
  name: 'RegisterView',
  components: {BreadcrumbsComponent},
  data() {
    return {
      firstname: null,
      nickname: null,
      email: null,
      emailConfirm: null,
      password: null,
      passwordConfirm: null,
      typeFieldPassword: "password",
      typeFieldPasswordConfirm: "password",
      showPasswordIcon: false,
      showPasswordConfirmIcon: false,
      confirmCGU: null,
      loadingRegisterBtn: false,
      dialogConditionUtilisation: false,
      audio: null,
      audioPlaying: false
    }
  },
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      firstname: {
        required: helpers.withMessage('Le prénom est requis.', required)
      },
      nickname: {
        required: helpers.withMessage('Le pseudo est requis.', required)
      },
      email: {
        required: helpers.withMessage("L'e-mail est requis.", required),
        email: helpers.withMessage("L'e-mail n'est pas une adresse e-mail valide.", email)
      },
      emailConfirm: {
        required: helpers.withMessage("La confirmation de l'e-mail est requise.", required),
        email: helpers.withMessage("L'e-mail n'est pas une adresse e-mail valide.", email),
        sameAsRef:  helpers.withMessage('Les e-mails ne correspondent pas.', sameAs(this.email))
      },
      password: {
        required: helpers.withMessage('Le mot de passe est requis.', required),
        minLength: helpers.withMessage('Le mot de passe est trop court.', (value) => value.length >= 8),
        maxLength: helpers.withMessage('Le mot de passe est trop long.', (value) => value.length <= 255)
      },
      passwordConfirm: {
        required: helpers.withMessage('La confirmation du mot de passe est requise.', required),
        sameAsPassword: helpers.withMessage('Les mots de passe ne correspondent pas.', sameAs(this.password)),
        minLength: helpers.withMessage('Le mot de passe est trop court.', (value) => value.length >= 8),
        maxLength: helpers.withMessage('Le mot de passe est trop long.', (value) => value.length <= 255)
      },
      confirmCGU: {
        required: helpers.withMessage("Les conditions d'utilisation du site sont requises.", required),
        sameAs: helpers.withMessage('Les conditions d\'utilisation du site sont requises.', sameAs(true)),
      }
    }
  },
  methods: {
    toggleAudio() {
      if (this.audioPlaying) {
        this.stopAudio();
      } else {
        this.playAudio();
      }
    },
    playAudio() {
      if (!this.audioPlaying) {
        const randomNumber = Math.floor(Math.random() * 4); // Sélectionne un nombre aléatoire entre 0 et 5
        const audioFileName = `condition_utilisation_music${randomNumber}.mp3`;
        this.audio = new Audio(require(`@/assets/audio/${audioFileName}`));
        this.audio.play();
        this.audioPlaying = true;
        this.audio.addEventListener('ended', () => {
          this.audioPlaying = false;
        });
      }
    },
    stopAudio() {
      if (this.audioPlaying && this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.audioPlaying = false;
      }
    },
    goTo(link) {
      router.push(link);
    },
    showPassword() {
      this.showPasswordIcon = !this.showPasswordIcon;
      this.typeFieldPassword === "text" ? this.typeFieldPassword = "password" : this.typeFieldPassword = "text";
    },
    showPasswordConfirm() {
      this.showPasswordConfirmIcon = !this.showPasswordConfirmIcon;
      this.typeFieldPasswordConfirm === "text" ? this.typeFieldPasswordConfirm = "password" : this.typeFieldPasswordConfirm = "text";
    },
    async sendRegister() {
      this.loadingRegisterBtn = true;
      const result = await this.v$.$validate()
      if (!result) {
        this.loadingRegisterBtn = false;
        return false;
      }
      await userStore().register(this.email, this.password, this.firstname, this.nickname);
      this.loadingRegisterBtn = false;
    }
  }
}
</script>
<style scoped>
#register {
  height: 100vh;
}
.carousel-img {
  height: 40vh
}
:deep(.v-label) {
  opacity: 1 !important;
}
:deep(.v-checkbox .v-messages) {
  text-align: center !important;
}
:deep(.v-messages) {
  text-align: left !important;
}
.cursor-text {
  cursor: pointer;
}
</style>