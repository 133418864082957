<template>
  <v-snackbar v-model="getSnackbarNotif.show" :color="getSnackbarNotif?.color" :timeout="getSnackbarNotif?.timeout">
    {{ getSnackbarNotif?.message }}
    <template v-slot:actions>
      <v-btn density="compact" icon="mdi-close" color="white" @click="getSnackbarNotif.show = false"></v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import {snackbarStore} from "@/store/snackbarStore";

export default {
  name: 'SnackbarsComponent',
  computed: {
    getSnackbarNotif() {
      return snackbarStore().snackbarNotifData;
    }
  }
}
</script>